import Header from '../common/Header';
import Footer from '../common/Footer';
import Banner from '../mission-support/Banner';
import MissionSupportInner from '../mission-support/MissionSupportInner';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function MissionSupport() {
  return (
    <div className="industrial_supply_page">
      <HelmetProvider>
        <Helmet>
          <title>Mission and Support</title>
          <meta name="description" content="Our mission is to provide cost-effective and transparent support solutions to our global clients with the best quality of products delivered on time." />
          <meta name="keywords" content="mission and visions, goals" />
        </Helmet>
      </HelmetProvider>

      <Header />
      <Banner />
      <MissionSupportInner />
      <Footer />
    </div>
  );
}

export default MissionSupport;
