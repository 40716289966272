function IndustriesInner() {
    return (

        <div className="industries-inner innersection">
            <section>
                <div className="container-fluid">
                    <div className="row mb-5">
                        <div className="col-md-12 wow fadeInUp" data-wow-duration="2s">
                            <p>We are a Leading Supplier of Industrial Solutions, Products and Engineered Equipment packages, our Clients are on the front line of the World's Toughest Industrial challenges.</p>
                            <p>Providing High-Quality Parts and Maintenance materials on time can make the difference between Success and Failure. Iggtglobal delivers OEM Replacement Parts when promised to help you Minimize Maintenance Downtime and Prevent Future Breakdowns. Our Clients count on Iggtglobal to Stock Critical items for planned Maintenance and Emergency Repairs. The fact our Clients regularly call us past Midnight for Plant Breakdowns or Support speaks volumes of our Credibility in the Industrial Sector.</p>
                            <p>By working with us on your Strategic Sourcing, you immediately have the expertise on hand to locate those Hard-to-Find items that may have been hidden behind a different SKU or Localized Part Number, identified under a new model number for the same product, or simply discontinued. And if what you need is simply to find and deliver readily available supplies and products to your order, we can do that too.</p>
                            <p>Designed to optimize your Supply Chain, our Master Agreement program designates an Account Manager to manage and monitor the inventory levels stocked at our facilities. With our efficient stocking services, you no longer have to worry about running out of Inventory. Let us manage your Supplies so that you keep only what you need to efficiently run your Plants and Operations.</p>
                        </div>
                    </div>
                    <div id="industries-card" className="dashboard-cards">
                        <div className="row">
                            <div className="col-lg-6 col-xl-4">
                                <div id="power" className="card wow fadeInUp" data-wow-duration="2s">
                                    <div className="card-title">
                                        <div className="title-box">
                                            <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/power.png" } alt="Power" />
                                            <h4>Power</h4>
                                        </div>
                                        <div className="card-arrow">»</div>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description">
                                            <p>Power Plant Maintenance Supplies represents a broad range including hundreds of sub-categories of products typically purchased on a regular basis, and is vital in keeping premises and equipment operational, a strong business partner such as Iggtglobal will reduce Downtime, Operational Cost and the stress of Restocking and keep the Plant Operational.</p>
                                            <p>While the primary focus of plant owners and operators is maintaining the highest safety standards, increasingly there’s the need to aid global competitiveness by finding ways to reduce costs and improve efficiency.</p>
                                            <p>That's why Power Plants use Iggtglobal as their primary supply partner - our history of commitment to service means we work alongside our clients to help them better operate, reduce total costs and deliver greater results today and in the future.</p>
                                        </div>
                                        <div className="card-flap flap2">
                                            <div className="card-actions"><a className="btn" href="#" onClick={e => e.preventDefault()}>Close</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                                <div id="oil-gas" className="card wow fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
                                    <div className="card-title">
                                        <div className="title-box">
                                            <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/oil-gas.png" } alt="Oil-Gas" />
                                            <h4>Oil &amp; Gas</h4>
                                        </div>
                                        <div className="card-arrow">»</div>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description">
                                            <p>The Oil and Gas industry requires complex equipment to execute daily operations safely and effectively. High Quality Tools are critical to the safety of the Rigger, Oil and Gas OEM’s know that working with the right Supply &amp; Fabrication partner is essential for producing High-Quality, Custom Products when they’re needed the most.</p>
                                            <p>With Experience providing Manufacturing and Value-Added Solutions for OEMs spanning a decade, Iggtglobal is the strategic partner for all of your Import, Supply, Designing and Fabrication needs.</p>
                                            <p>Even though purchases are often for low-value items and tail end spends, stocking up on unneeded items can be expensive because it ties up capital, this is another component of the value added solutions provided by Iggtglobal in support of your Plant.</p>
                                        </div>
                                        <div className="card-flap flap2">
                                            <div className="card-actions"><a className="btn" href="#" onClick={e => e.preventDefault()}>Close</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                                <div id="steel" className="card wow fadeInUp" data-wow-duration="2s" data-wow-delay=".3s">
                                    <div className="card-title">
                                        <div className="title-box">
                                            <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/steel.png" } alt="Steel" />
                                            <h4>Steel</h4>
                                        </div>
                                        <div className="card-arrow">»</div>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description">
                                            <p>End users are looking for quality products at the right price delivered by knowledgeable experts.  Our team of Experts with experience in the Metal Industry, as an Engineering company working with the foremost Steel Plants provides unique insights in addressing challenges. We understand and hone the Plant Equipment Technologies and Metal Utilization Technologies that we have amassed while working with the Steel manufacturing industry to promote social and industrial infrastructure projects in the UAE.</p>
                                            <p>A Strong business partner such as Iggtglobal will reduce Downtime, Save Cost and the Lower the general stress of Restocking.</p>
                                            <p>Since most of our clients have older Steel Plants, Obsolete Products are a recurring concern and our area of expertise. Our relationship with this Industry is forged in Steel.</p>
                                        </div>
                                        <div className="card-flap flap2">
                                            <div className="card-actions"><a className="btn" href="#" onClick={e => e.preventDefault()}>Close</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                                <div id="mining" className="card wow fadeInUp" data-wow-duration="2s" data-wow-delay=".4s">
                                    <div className="card-title">
                                        <div className="title-box">
                                            <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/mining.png" } alt="Mining" />
                                            <h4>Mining</h4>
                                        </div>
                                        <div className="card-arrow">»</div>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description">
                                            <p>Running out of Industrial Supplies like Plant Components, Safety Gear and Lubricants, especially when operating a Mine in Remote and Difficult Terrain, can cause any job to come to a halt. Industrial Hand Tools get lost and Industrial Power Tools wear out with Zero availability for replacements in the vicinity, these are real life situations. Our inventories help you get the right tools, the right equipment, and the right supplies for the harshest work environment and the job at hand.</p>
                                            <p>Our facility is equipped with the most up-to-date equipment, stringent compliance to quality standards. We stock some the more basic industrial products at our Dubai Facility and can have non-stocked industrial supplies delivered from across the globe with the lowest possible lead time.</p>
                                        </div>
                                        <div className="card-flap flap2">
                                            <div className="card-actions"><a className="btn" href="#" onClick={e => e.preventDefault()}>Close</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                                <div id="government" className="card wow fadeInUp" data-wow-duration="2s" data-wow-delay=".5s">
                                    <div className="card-title">
                                        <div className="title-box">
                                            <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/government.png" } alt="Government" />
                                            <h4>Government</h4>
                                        </div>
                                        <div className="card-arrow">»</div>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description">
                                            <p>Iggtglobal provides our Government and Institutional Clients with Proactive, Innovative and Cost Effective solutions as a Dedicated and Trusted partner to our Public Sector clients. It takes effort to keep public facilities operational and citizens safe. Any Government Facility or team equipped with the correct product mix and proper training results in High Productivity levels.</p>
                                            <p>We also understand Restricted Budgets, Time Constraints and Reduced Manpower which is a reality story of every Government run Institution.</p>
                                            <p>We specialize in supporting Government Departments &amp; Institutions across the region in achieving their goals.  We evaluate products and analyze procedures. Our experienced team members suggest products and systems that reduce Cost, Time and Labor.  In addition, we train your maintenance crew on proper techniques and procedures.</p>
                                        </div>
                                        <div className="card-flap flap2">
                                            <div className="card-actions"><a className="btn" href="#" onClick={e => e.preventDefault()}>Close</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                                <div id="aviation" className="card wow fadeInUp" data-wow-duration="2s" data-wow-delay=".6s">
                                    <div className="card-title">
                                        <div className="title-box">
                                            <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/aviation.png" } alt="Aviation" />
                                            <h4>Aviation</h4>
                                        </div>
                                        <div className="card-arrow">»</div>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description">
                                            <p>Your flying operations should run uninterrupted. For that you need spare parts and material to be available when and where you need them - not least for the unplanned, critical maintenance events. Look to Iggtglobal to provide all the aircraft supplies you need for the Maintenance, Repair and Operation of your fleet of Aircraft or Drones. We offer a full range of MRO supplies</p>
                                            <p>Some of the MRO supplies we sell include paint supplies, adhesives, hazmat boxes, poly bags, resin, tubing, fasteners, chemicals, magnetic sweepers and more. We can outfit your entire operation with everything you need to run a safe and efficient MRO operation. We supply Airlines, Government Agencies and Drone Manufacturers with both large and small orders of aircraft MRO supplies, and offer the quickest RFQ turnaround time in the industry.</p>
                                        </div>
                                        <div className="card-flap flap2">
                                            <div className="card-actions"><a className="btn" href="#" onClick={e => e.preventDefault()}>Close</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                                <div id="aluminum" className="card wow fadeInUp" data-wow-duration="2s" data-wow-delay=".7s">
                                    <div className="card-title">
                                        <div className="title-box">
                                            <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/aluminum.png" } alt="Aluminum" />
                                            <h4>Aluminum</h4>
                                        </div>
                                        <div className="card-arrow">»</div>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description">
                                            <p>Aluminum with its ability to multitask has seen continuous demand growth, Smelters continue expansion with increased capabilities for Aluminum production, and Aluminum with its Strength, Durability, Recyclability, and Weight make it the go-to choice for a wide and diverse range of mission-critical applications, such as Aerospace, Automotive, Defense and Industries</p>
                                            <p>Our Service &amp; Supply teams provide existing Aluminum Smelting plants with services and products relating to Maintenance, Modernization, Replacement and Capacity increase operations. Our Team has considerable experience working with various Aluminum Smelter areas like Casthouse, Reduction, Carbon and Refractory.</p>
                                            <p>We maintain a fleet delivery vehicles allowing Iggtglobal to deliver supplies when they are needed. Our locations are staffed with knowledgeable and experienced sales and delivery personnel.</p>
                                        </div>
                                        <div className="card-flap flap2">
                                            <div className="card-actions"><a className="btn" href="#" onClick={e => e.preventDefault()}>Close</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                                <div id="ports" className="card wow fadeInUp" data-wow-duration="2s" data-wow-delay=".8s">
                                    <div className="card-title">
                                        <div className="title-box">
                                            <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/ports.png" } alt="Ports" />
                                            <h4>Ports</h4>
                                        </div>
                                        <div className="card-arrow">»</div>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description">
                                            <p>Modern Airports and Seaports around the Globe need to be equipped with professional and quality equipment. The rapid evolution of technologies and continued growth of global passenger and freight movement requires Sea and Airports to be equipped with the latest up-to-date equipment and devices along with dedicated service providers like Iggtglobal.</p>
                                            <p>Our support offices in United States, London and China enables us to source equipment from around the world that are delivered to your Airport or Seaport, on time and on budget. From Lighting to spare parts for their dockside equipment, Iggtglobal will ensure that any machine downtime is kept to a minimum - whether that’s a Crane, Winch, Hoist or other Handling Equipment, Security Equipment, Ground and Sea Safety Devices.</p>
                                        </div>
                                        <div className="card-flap flap2">
                                            <div className="card-actions"><a className="btn" href="#" onClick={e => e.preventDefault()}>Close</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                                <div id="humanitarian" className="card wow fadeInUp" data-wow-duration="2s" data-wow-delay=".9s">
                                    <div className="card-title">
                                        <div className="title-box">
                                            <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/humanitarian.png" } alt="Humanitarian" />
                                            <h4>Humanitarian</h4>
                                        </div>
                                        <div className="card-arrow">»</div>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description">
                                            <p>The consequences of Conflicts, Natural Disasters and other Emergencies can be staggering, they have created one of the greatest humanitarian crisis in the recent age. Iggtglobal is a trusted partner for UN Agencies, Charities and NGOs the world over, supplying them a variety of equipment to long term development projects and humanitarian response operations.</p>
                                            <p>Our Supplies are Innovative, Good Value and Cost-Effective Refugee Shelters, Core relief products such as Mosquito repellent Tarpaulins, Tents, Wash &amp; Hygiene Kits, Kitchen Equipment and Life Support Supplies</p>
                                            <p>Any Natural Disaster or Conflict are extreme, sudden events caused by a host of different factors. These disasters can't be controlled but surely the effects can be minimized by being ready for it in advance and can make a tremendous difference to those in need.</p>
                                        </div>
                                        <div className="card-flap flap2">
                                            <div className="card-actions"><a className="btn" href="#" onClick={e => e.preventDefault()}>Close</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                                <div id="food-industry" className="card wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
                                    <div className="card-title">
                                        <div className="title-box">
                                            <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/food.png" } alt="Food" />
                                            <h4>Food Industry</h4>
                                        </div>
                                        <div className="card-arrow">»</div>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description">
                                            <p>Working in a no-compromise industry like Food Production is tough. Let us help make your job easier by bringing you products that help you maintain Safety, Food Regulatory Compliance, Waste reduction plus the high standards you set for your products. We know that if you have a problem with your food production line it impacts on your business’s profitability.</p>
                                            <p>We understand that parts sourced or manufactured for food processing must meet a variety of Regulatory standards. Iggtglobal has vast experience working with the corrosion-resistant metals and materials required to meet food processing equipment product specifications and regulations. We source and fabricate reliable parts that will minimize downtime and ensure quality. Only the best is good enough when we help Clients streamline their Food Processing Lines.</p>
                                        </div>
                                        <div className="card-flap flap2">
                                            <div className="card-actions"><a className="btn" href="#" onClick={e => e.preventDefault()}>Close</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                                <div id="utilities" className="card wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.1s">
                                    <div className="card-title">
                                        <div className="title-box">
                                            <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/utilities.png" } alt="Utilities" />
                                            <h4>Utilities</h4>
                                        </div>
                                        <div className="card-arrow">»</div>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description">
                                            <p>As a Full Service Distributor, Iggtglobal provides Electrical and Industrial Maintenance, Repair, and Operations related products along with servicing the needs for Waterworks, Power Generation, Transmission and Distribution for Government Owned Utilities, Desalination and Power Stations. In the Industrial Market, our Clients are our Commitment, and we are committed to Supplying Excellence, Service and Value.</p>
                                            <p>With Utilities, Downtime can create Turmoil and our job is prevent that, Our Expert Technicians and Skilled Manpower ensure that you have access to optimized services solutions along with our range of product solutions.</p>
                                            <p>We offer full line of products for Electrical Grids, Water Lines, Waste Water, Desalination and Gas that include Power Cables, Electrical Components, Pipe, Fittings, Valves, Gaskets and Tools for Municipal Power, Water and Sewage Facilities.</p>
                                        </div>
                                        <div className="card-flap flap2">
                                            <div className="card-actions"><a className="btn" href="#" onClick={e => e.preventDefault()}>Close</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-4">
                                <div id="cooling-towers" className="card wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.3s">
                                    <div className="card-title">
                                        <div className="title-box">
                                            <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/cooling-towers.png" } alt="Cooling Towers" />
                                            <h4>Cooling Towers</h4>
                                        </div>
                                        <div className="card-arrow">»</div>
                                    </div>
                                    <div className="card-flap flap1">
                                        <div className="card-description">
                                            <p>Iggtglobal carries out Design, Installation, Maintenance and Upgrades of existing Cooling Towers, and Supply of Spare Parts for Cooling Towers of any type and make. In case of Emergencies, we are ready to get your Cooling Tower operating again in the shortest time. Our team is made of Experts trained for Repairs and Maintenance of all types of Cooling Towers whether the Cooling Towers are on-line of off-line.</p>
                                            <p>Iggtglobal strongly recommends that all Cooling Towers have a routine Cleaning and Maintenance program in place, to ensure that the Towers operate safely, reliably and efficiently at all times.</p>
                                            <p>Service &amp; Supply agreements can be tailor made to meet client specific requirements both in terms of scope of supply involved and frequency of service.</p>
                                        </div>
                                        <div className="card-flap flap2">
                                            <div className="card-actions"><a className="btn" href="#" onClick={e => e.preventDefault()}>Close</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default IndustriesInner;