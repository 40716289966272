function IndustriesType() {
    return (

      <div className="Industries services section">
        
        <div className="dots-particle">
          <div className="dots">
            <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
          </div>
        </div>

        <div className="container-fluid">
          <div id="desk-view">
              <div className="tabbed-content tabs-side">
                <section id="industries_tab_circle" className="outer_tab item active" data-title="Industries">
                    <div className="item-content">
                      <div className="item-border">
                          <div className="inner-tabbed-content">
                            <nav className="tabs inner-tabs">
                                <ul>
                                  <li className="active"><a id="power" href="#" onClick={e => e.preventDefault()} className="wow zoomIn active" data-wow-duration="2s" data-wow-delay=".2s">Power <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/power-small.png" } alt="Power" /></a></li>
                                  <li className=""><a id="oil_gas" href="#" onClick={e => e.preventDefault()} className="wow zoomIn" data-wow-duration="2s" data-wow-delay=".4s">Oil &amp; Gas <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/oil-gas-small.png" } alt="Oil-Gas" /></a></li>
                                  <li className=""><a id="steel" href="#" onClick={e => e.preventDefault()} className="wow zoomIn" data-wow-duration="2s" data-wow-delay=".6">Steel <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/steel-small.png" } alt="Steel" /></a></li>
                                  <li className=""><a id="mining" href="#" onClick={e => e.preventDefault()} className="wow zoomIn" data-wow-duration="2s" data-wow-delay=".8s">Mining <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/mining-small.png" } alt="Mining" /></a></li>
                                  <li className=""><a id="government" href="#" onClick={e => e.preventDefault()} className="wow zoomIn" data-wow-duration="2s" data-wow-delay="1s">Government <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/government-small.png" } alt="Government" /></a></li>
                                  <li className=""><a id="aviation" href="#" onClick={e => e.preventDefault()} className="wow zoomIn" data-wow-duration="2s" data-wow-delay="1.2s">Aviation <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/aviation-small.png" } alt="Aviation" /></a></li>
                                  <li className=""><a id="aluminum" href="#" onClick={e => e.preventDefault()} className="wow zoomIn" data-wow-duration="2s" data-wow-delay="1.4s"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/aluminum-small.png" } alt="Aluminum" /> Aluminum</a></li>
                                  <li className=""><a id="ports" href="#" onClick={e => e.preventDefault()} className="wow zoomIn" data-wow-duration="2s" data-wow-delay="1.6s"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/ports-small.png" } alt="Ports" /> Ports</a></li>
                                  <li className=""><a id="humanitarian" href="#" onClick={e => e.preventDefault()} className="wow zoomIn" data-wow-duration="2s" data-wow-delay="1.8s"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/humanitarian-small.png" } alt="Humanitarian" />Humanitarian</a></li>
                                  <li className=""><a id="food" href="#" onClick={e => e.preventDefault()} className="wow zoomIn" data-wow-duration="2s" data-wow-delay="2s"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/food-small.png" } alt="Food" />Food Industry</a></li>
                                  <li className=""><a id="utilities" href="#" onClick={e => e.preventDefault()} className="wow zoomIn" data-wow-duration="2s" data-wow-delay="2.2s"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/utilities-small.png" } alt="Utilities" />Utilities</a></li>
                                  <li className=""><a id="cooling-towers" href="#" onClick={e => e.preventDefault()} className="wow zoomIn" data-wow-duration="2s" data-wow-delay="2.4s"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/cooling-towers-small.png" } alt="Cooling Towers" />Cooling Towers</a></li>
                                </ul>
                            </nav>
                            <section id="power_tab" className="item wow zoomIn active" data-wow-duration="2s" data-title="Power">
                                <div className="inner-item-content">
                                  <h2>Power</h2>
                                  <p>Power Plant Maintenance Supplies represents a broad range including hundreds of sub-categories of products typically purchased on a regular basis, and is vital in keeping premises and equipment operational, a strong business partner such as Iggtglobal will reduce Downtime, Operational Cost and the stress of Restocking and keep the Plant Operational</p>
                                  <a className="btn-read-more hoverlink" href={process.env.REACT_APP_BASE_URL + "/industries#power"}>Read More</a>
                                </div>
                            </section>
                            <section id="oil_gas_tab" className="item wow zoomIn" data-wow-duration="2s" data-title="Oil &amp; Gas">
                                <div className="inner-item-content">
                                  <h2>Oil &amp; Gas</h2>
                                  <p>The Oil and Gas industry requires complex equipment to execute daily operations safely and effectively. High Quality Tools are critical to the safety of the Rigger, Oil and Gas OEM’s know that working with the right Supply &amp; Fabrication partner is essential for producing High-Quality, Custom Products when they’re needed the most.</p>
                                  <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#oil-gas"}>Read More</a>
                                </div>
                            </section>
                            <section id="steel_tab" className="item wow zoomIn" data-wow-duration="2s" data-title="Steel">
                                <div className="inner-item-content">
                                  <h2>Steel</h2>
                                  <p>Steel-Making is a competitive Industry, End users are looking for quality products at the right price delivered by knowledgeable experts, Our team of Experts with experience in the Metal Industry and as an Engineering company working with leading Steel Plants in the region, We provide unique insights in addressing challenges in production.</p>
                                  <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#steel"}>Read More</a>
                                </div>
                            </section>
                            <section id="mining_tab" className="item wow zoomIn" data-wow-duration="2s" data-title="Mining">
                                <div className="inner-item-content">
                                  <h2>Mining</h2>
                                  <p>Running out of industrial supplies like Plant Components, Safety Gear and Lubricants, especially when operating a Mine in remote and difficult terrain, can cause any job to come to a halt, the way we extract and produce these Metals and Minerals is changing. Seismic shifts in technology and growing Environmental concerns continue to shape our solutions for the Mining Industry</p>
                                  <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#mining"}>Read More</a>
                                </div>
                            </section>
                            <section id="government_tab" className="item wow zoomIn" data-wow-duration="2s" data-title="Government">
                                <div className="inner-item-content">
                                  <h2>Government</h2>
                                  <p>Iggtglobal provides our Government and Institutional Clients with Proactive, Innovative and Cost Effective solutions as a Dedicated and Trusted partner to our public sector clients. It takes effort to keep public facilities operational and citizens safe. Any Government facility or team equipped with the correct product mix and proper training results in high productivity levels.</p>
                                  <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#government"}>Read More</a>
                                </div>
                            </section>
                            <section id="aviation_tab" className="item wow zoomIn" data-wow-duration="2s" data-title="Aviation">
                                <div className="inner-item-content">
                                  <h2>Aviation</h2>
                                  <p>Your flying operations should run uninterrupted. For that you need spare parts and material to be available when and where you need them - not least for the unplanned, critical maintenance events. Look to Iggtglobal to provide all the aircraft supplies you need for the Maintenance, Repair and Operation of your fleet of Aircraft or Drones. We offer a full range of MRO supplies</p>
                                  <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#aviation"}>Read More</a>
                                </div>
                            </section>
                            <section id="aluminum_tab" className="item wow zoomIn" data-wow-duration="2s" data-title="Aluminum">
                                <div className="inner-item-content">
                                  <h2>Aluminum</h2>
                                  <p>Aluminum with its ability to multitask has seen continuous demand growth, Smelters continue expansion with increased capabilities for Aluminum production, and Aluminum with its Strength, Durability, Recyclability, and Weight make it the go-to choice for a wide and diverse range of mission-critical applications, such as Aerospace, Automotive, Defense and Industries</p>
                                  <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#aluminum"}>Read More</a>
                                </div>
                            </section>
                            <section id="ports_tab" className="item wow zoomIn" data-wow-duration="2s" data-title="Ports">
                                <div className="inner-item-content">
                                  <h2>Ports</h2>
                                  <p>Modern Airports and Seaports around the globe need to be equipped with professional and quality equipment. The rapid evolution of technologies and continued growth of global passenger and freight movement requires sea and airports to be equipped with the latest up-to-date equipment and devices along with dedicated service providers like Iggtglobal.</p>
                                  <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#ports"}>Read More</a>
                                </div>
                            </section>
                            <section id="humanitarian_tab" className="item wow zoomIn" data-wow-duration="2s" data-title="Humanitarian">
                                <div className="inner-item-content">
                                  <h2>Humanitarian</h2>
                                  <p>The consequences of Conflicts, Natural Disasters and other Emergencies can be staggering, they have created one of the greatest humanitarian crisis in the recent age. Iggtglobal is a trusted partner for UN Agencies, Charities and NGOs the world over, supplying them a variety of equipment to long term development projects and humanitarian response operations.</p>
                                  <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#humanitarian"}>Read More</a>
                                </div>
                            </section>
                            <section id="food_tab" className="item wow zoomIn" data-wow-duration="2s" data-title="Food">
                                <div className="inner-item-content">
                                  <h2>Food Industry</h2>
                                  <p>Working in a no-compromise industry like Food Production is tough. Let us help make your job easier by bringing you products that help you maintain Safety, Food Regulatory Compliance, Waste reduction plus the high standards you set for your products. We know that if you have a problem with your food production line it impacts on your business’s profitability.</p>
                                  <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#food-industry" }>Read More</a>
                                </div>
                            </section>
                            <section id="utilities_tab" className="item wow zoomIn" data-wow-duration="2s" data-title="Utilities">
                                <div className="inner-item-content">
                                  <h2>Utilities</h2>
                                  <p>As a full service Distributor, Iggtglobal provides Electrical, Piping and Industrial Maintenance, Repair, and Operation Products, Plant Spare Parts and Consumables along with experienced Certified Technicians for servicing the needs for Waterworks, Power Generation, Transmission and Distribution for Government Owned Utilities, Desalination Plants and Power Stations.</p>
                                  <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#utilities"}>Read More</a>
                                </div>
                            </section>
                            <section id="cooling-towers_tab" className="item wow zoomIn" data-wow-duration="2s" data-title="Cooling Towers">
                                <div className="inner-item-content">
                                  <h2>Cooling Towers</h2>
                                  <p>Iggtglobal carries out Design, Installation, Maintenance and Upgrades of existing Cooling Towers, and Supply of Spare Parts for Cooling Towers of any type and make. In case of Emergencies, we are ready to get your Cooling Tower operating again in the shortest time.</p>
                                  <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industrial-cooling-towers" }>Read More</a>
                                </div>
                            </section>
                          </div>
                      </div>
                    </div>
                </section>
              </div>
          </div>

          <div id="mob-view">
            <div className="nav-wrap">
              <nav className="acnav" role="navigation">
                  <ul className="acnav__list acnav__list--level1">
                    <li className="has-children is-open">
                        <div className="acnav__label">Industries</div>
                        <ul className="acnav__list acnav__list--level2">
                          <li className="has-children">
                              <div className="acnav__label acnav__label--level2">Power</div>
                              <ul className="acnav__list acnav__list--level3">
                                <li>
                                    <h2>Power</h2>
                                    <p>Power Plant Maintenance Supplies represents a broad range including hundreds of sub-categories of products typically purchased on a regular basis, and is vital in keeping premises and equipment operational, a strong business partner such as Iggtglobal will reduce Downtime, Operational Cost and the stress of Restocking and keep the Plant Operational</p>
                                    <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#power"}>Read More</a>
                                </li>
                              </ul>
                          </li>
                          <li className="has-children">
                              <div className="acnav__label acnav__label--level2">Oil &amp; Gas</div>
                              <ul className="acnav__list acnav__list--level3">
                                <li>
                                    <h2>Oil &amp; Gas</h2>
                                    <p>The Oil and Gas industry requires complex equipment to execute daily operations safely and effectively. High Quality Tools are critical to the safety of the Rigger, Oil and Gas OEM’s know that working with the right Supply &amp; Fabrication partner is essential for producing High-Quality, Custom Products when they’re needed the most.</p>
                                    <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#oil-gas"}>Read More</a>
                                </li>
                              </ul>
                          </li>
                          <li className="has-children">
                              <div className="acnav__label acnav__label--level2">Steel</div>
                              <ul className="acnav__list acnav__list--level3">
                                <li>
                                    <h2>Steel</h2>
                                    <p>Steel-Making is a competitive Industry, End users are looking for quality products at the right price delivered by knowledgeable experts, Our team of Experts with experience in the Metal Industry and as an Engineering company working with leading Steel Plants in the region, We provide unique insights in addressing challenges in production.</p>
                                    <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#steel"}>Read More</a>
                                </li>
                              </ul>
                          </li>
                          <li className="has-children">
                              <div className="acnav__label acnav__label--level2">Mining</div>
                              <ul className="acnav__list acnav__list--level3">
                                <li>
                                    <h2>Mining</h2>
                                    <p>Running out of industrial supplies like Plant Components, Safety Gear and Lubricants, especially when operating a Mine in remote and difficult terrain, can cause any job to come to a halt, the way we extract and produce these Metals and Minerals is changing. Seismic shifts in technology and growing Environmental concerns continue to shape our solutions for the Mining Industry</p>
                                    <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#mining"}>Read More</a>
                                </li>
                              </ul>
                          </li>
                          <li className="has-children">
                              <div className="acnav__label acnav__label--level2">Government</div>
                              <ul className="acnav__list acnav__list--level3">
                                <li>
                                    <h2>Government</h2>
                                    <p>Iggtglobal provides our Government and Institutional Clients with Proactive, Innovative and Cost Effective solutions as a Dedicated and Trusted partner to our public sector clients. It takes effort to keep public facilities operational and citizens safe. Any Government facility or team equipped with the correct product mix and proper training results in high productivity levels.</p>
                                    <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#government"}>Read More</a>
                                </li>
                              </ul>
                          </li>
                          <li className="has-children">
                              <div className="acnav__label acnav__label--level2">Aviation</div>
                              <ul className="acnav__list acnav__list--level3">
                                <li>
                                    <h2>Aviation</h2>
                                    <p>Your flying operations should run uninterrupted. For that you need spare parts and material to be available when and where you need them - not least for the unplanned, critical maintenance events. Look to Iggtglobal to provide all the aircraft supplies you need for the Maintenance, Repair and Operation of your fleet of Aircraft or Drones. We offer a full range of MRO supplies</p>
                                    <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#aviation"}>Read More</a>
                                </li>
                              </ul>
                          </li>
                          <li className="has-children">
                              <div className="acnav__label acnav__label--level2">Aluminum</div>
                              <ul className="acnav__list acnav__list--level3">
                                <li>
                                    <h2>Aluminum</h2>
                                    <p>Aluminum with its ability to multitask has seen continuous demand growth, Smelters continue expansion with increased capabilities for Aluminum production, and Aluminum with its Strength, Durability, Recyclability, and Weight make it the go-to choice for a wide and diverse range of mission-critical applications, such as Aerospace, Automotive, Defense and Industries</p>
                                    <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#aluminum"}>Read More</a>
                                </li>
                              </ul>
                          </li>
                          <li className="has-children">
                              <div className="acnav__label acnav__label--level2">Ports</div>
                              <ul className="acnav__list acnav__list--level3">
                                <li>
                                    <h2>Ports</h2>
                                    <p>Modern Airports and Seaports around the globe need to be equipped with professional and quality equipment. The rapid evolution of technologies and continued growth of global passenger and freight movement requires sea and airports to be equipped with the latest up-to-date equipment and devices along with dedicated service providers like Iggtglobal.</p>
                                    <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#ports"}>Read More</a>
                                </li>
                              </ul>
                          </li>
                          <li className="has-children">
                              <div className="acnav__label acnav__label--level2">Humanitarian</div>
                              <ul className="acnav__list acnav__list--level3">
                                <li>
                                    <h2>Humanitarian</h2>
                                    <p>The consequences of Conflicts, Natural Disasters and other Emergencies can be staggering, they have created one of the greatest humanitarian crisis in the recent age. Iggtglobal is a trusted partner for UN Agencies, Charities and NGOs the world over, supplying them a variety of equipment to long term development projects and humanitarian response operations.</p>
                                    <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#humanitarian"}>Read More</a>
                                </li>
                              </ul>
                          </li>
                          <li className="has-children">
                              <div className="acnav__label acnav__label--level2">Food Industry</div>
                              <ul className="acnav__list acnav__list--level3">
                                <li>
                                    <h2>Food Industry</h2>
                                    <p>Working in a no-compromise industry like Food Production is tough. Let us help make your job easier by bringing you products that help you maintain Safety, Food Regulatory Compliance, Waste reduction plus the high standards you set for your products. We know that if you have a problem with your food production line it impacts on your business’s profitability.</p>
                                    <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#food-industry"}>Read More</a>
                                </li>
                              </ul>
                          </li>
                          <li className="has-children">
                              <div className="acnav__label acnav__label--level2">Utilities</div>
                              <ul className="acnav__list acnav__list--level3">
                                <li>
                                    <h2>Utilities</h2>
                                    <p>As a full service Distributor, Iggtglobal provides Electrical, Piping and Industrial Maintenance, Repair, and Operation Products, Plant Spare Parts and Consumables along with experienced Certified Technicians for servicing the needs for Waterworks, Power Generation, Transmission and Distribution for Government Owned Utilities, Desalination Plants and Power Stations.</p>
                                    <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industries#utilities"}>Read More</a>
                                </li>
                              </ul>
                          </li>
                          <li className="has-children">
                              <div className="acnav__label acnav__label--level2">Cooling Towers</div>
                              <ul className="acnav__list acnav__list--level3">
                                <li>
                                    <h2>Cooling Towers</h2>
                                    <p>Iggtglobal carries out Design, Installation, Maintenance and Upgrades of existing Cooling Towers, and Supply of Spare Parts for Cooling Towers of any type and make. In case of Emergencies, we are ready to get your Cooling Tower operating again in the shortest time.</p>
                                    <a className="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industrial-cooling-towers"}>Read More</a>
                                </li>
                              </ul>
                          </li>
                        </ul>
                    </li>
                  </ul>
              </nav>
            </div>
          </div>
        </div>

      </div>

    );
  }
  
  export default IndustriesType;