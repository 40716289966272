function Why_IGGT_Inner() {
    return (

        <div class="why-iggt-inner innersection">

            <section>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12 wow fadeInUp" data-wow-duration="2s">
                            <p>“Iggtglobal, based in the Global Logistics and Business Hub of Dubai, is an Independent Family-Owned company with Global Market reach in the Business-to-Business distribution of Industrial Supplies &amp; Services across 12 unique Market Sectors. Our unique location coupled with Capabilities and an Unyielding Commitment to Excellence assures we can easily support our Far-Reaching and Adverse Customer requirements, whether within the Gulf and the Middle East, across Central Asia and throughout Africa. Established in 2009, Iggtglobal has developed Strong Partnerships with many of the World’s leading Organizations and Support their interests across the Middle East, Central Asia and Africa through the expertise of our team of Sourcing, Engineering and Logistics Specialists possessing the Training and Acumen to develop Solutions tailored to each Client’s unique needs”</p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="pt-0 pb-0">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="d-flex flex-row justify-content-between flexbox first">
                                <div class="flex-heading wow fadeInLeft" data-wow-duration="2s">
                                    <div class="d-flex flex-row">
                                        <h3><span>1.</span></h3>
                                        <h3>Critical <br/>Components</h3>
                                    </div>
                                </div>
                                <div class="flex-txt wow fadeInRight" data-wow-duration="2s">
                                    <p>“Our Clients operate on the Front Lines of the World’s Harshest Locations to tackle Complex, Difficult Challenges, in these Challenging Conditions a Small Component that fails to arrive on time could put Complex Operations on Standby or Complete Halt. When Deployed in Challenging Settings, there is rarely an awareness of when Operations will break down. Having Iggtglobal as your Partner minimizes the Financial Impact whilst Enduring operations are up and running as quickly as possible. We protect your Assets and ensure your Supply Chain has the support of a 24/7/365 Operation, provisioning Critical Parts and Supplies globally.”</p>
                                </div>
                            </div>
                            <div class="d-flex flex-row justify-content-between flexbox second">
                                <div class="flex-heading wow fadeInLeft" data-wow-duration="2s" data-wow-delay=".2s">
                                    <div class="d-flex flex-row">
                                        <h3><span>2.</span></h3>
                                        <h3>Quality &amp; Time</h3>
                                    </div>
                                </div>
                                <div class="flex-txt wow fadeInRight" data-wow-duration="2s" data-wow-delay=".3s">
                                    <p>“With the support of the Iggtglobal team, our Ambitiously High Benchmarks transcend all we do. Since 2015, we have been ISO 9001:2015 and 14001:2015 Certified. This Certification is based on a number of Quality Management principles with a Client focus. ISO requires processes that work to Eliminate Errors and Continually Improve the Client Experience. Our efforts to uphold these Rigorous Standards make us more Efficient while Delivering Value and Cost Savings for our Clients. Iggtglobal’s commitment to Quality is unyielding. We emphasize On-Time Delivery. Our Highly-Trained Logistics team coordinate every order. They are ready for Surprise Challenges, Last-Minute Changes, and Expedited Missions.”</p>
                                </div>
                            </div>
                            <div class="d-flex flex-row justify-content-between flexbox third">
                                <div class="flex-heading wow fadeInLeft" data-wow-duration="2s" data-wow-delay=".4s">
                                    <div class="d-flex flex-row">
                                        <h3><span>3.</span></h3>
                                        <h3>Compliance </h3>
                                    </div>
                                </div>
                                <div class="flex-txt wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.5s">
                                    <p>“Our Clients work in the most Regulated Sectors of the Planet, ensuring Full Compliance at all stages of the Business Process is standard procedure at Ismail Gheewala General Trading LLC. Our Clients expect and demand the most Rigorous Criteria to be applied across all Compliance issues – from Contractor Health &amp; Safety through to Customs Clearance – and we follow the same Principles. Internally, we invest a great deal of Time and Resources into ensuring Compliance is at the forefront of all we do and is managed to the Highest Standards with the support of our Consultants. We take pride in the fact that we work with Clients in the World’s Most Regulated Sectors and follow Compliance Processes and Procedures that are certified to the most Stringent Standards. At Iggtglobal, Compliance dictates our Conduct, our Policies and our Procedures across and shapes our performance in Exceeding Quality Specifications our Clients' Demand, Special Packaging, to links between Logistics and Delivery Systems.”</p>
                                </div>
                            </div>
                            <div class="d-flex flex-row justify-content-between flexbox forth">
                                <div class="flex-heading wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div class="d-flex flex-row">
                                        <h3><span>4.</span></h3>
                                        <h3>Global <br/>Operations</h3>
                                    </div>
                                </div>
                                <div class="flex-txt wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.7s">
                                    <p>Our Global Supply Chain capabilities can provide your Business with End-to-End, Fully Scalable Supply Chain Solutions. Our Support Offices in the United States, China &amp; United Kingdom have given us the Strategic Advantage of facilitating innovative options and World-Class possibilities for our Clients.</p>
                                    <p>Our Operations span across the Middle East, Central Asia, and West &amp; Central Africa with 10 ongoing Sites at any point in time</p>
                                    <p>Established Long Term Partnerships have helped us maintain a Strong Presence within our Sectors of Operation and facilitated our growth within them, Our responsibilities are not limited to managing the entire Supply Chain Operation for our Clients but to ensure that their Operations move forward in the most Cost-Effective and Compliant way.</p>
                                    <p>Improvisation is one of our Strong areas, our Company imports cargo from 45+ Countries globally and each Country will have its own Export Regulation, Transport, Documentation, Customs, Local Taxes and Laws, and that Cargo is delivered to a Remote Site across 22 Countries where in addition we have the complexity of Protocols and Ambiguous Regulations, navigating these systems while ensuring a High Level of Compliance is the experience we’ve earned.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="map-sec">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-10 text-center">
                            <div class="mask-skew wow fadeIn" data-wow-offset="150" data-wow-duration="1s" data-wow-delay=".5s">
                                <div class="mask wow slideInLeft" data-wow-offset="150" data-wow-duration="2s" data-wow-delay=".5s">
                                    <div class="inside"></div>
                                </div>
                                <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/map.png" } alt="map" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </div>

    );
}
export default Why_IGGT_Inner;