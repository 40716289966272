function Banner() {
    return (
        
        <div className="banner why-iggt">
            <div className="centerbox">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <h1 className="wow zoomIn" data-wow-duration="2s">Why IGGTGlobal?</h1>
                        </div>
                    </div>
                </div>
                <div className="fixedlogo">
                    <a href={ process.env.REACT_APP_BASE_URL + "/"}><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/logo.png" } alt="logo" /></a>
                </div>
            </div>
            <div className="why-iggt-bg">
                <canvas id="canvas"></canvas>
            </div>
        </div>

    );
}

export default Banner;