import Header from '../common/Header';
import Footer from '../common/Footer';
import Banner from '../industrial-supply/Banner';
import IndustrialSupplyInner from '../industrial-supply/IndustrialSupplyInner';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function IndustrialSupply() {
  return (
    <div className="industrial_supply_page">
      <HelmetProvider>
        <Helmet>
          <title>Industrial Distribution and Supply Chain - IGGTGLOBAL</title>
          <meta name="description" content="IGGTGLOBAL is a pioneer leader in supply chain management for various industrial supply demands and supplies needed throughout the Gulf region." />
          <meta name="keywords" content="industrial supply, supply chain management" />        
          <script type="text/javascript" src={ process.env.REACT_APP_BASE_URL + "/assets/js/text-animation.js" }></script>
        </Helmet>
      </HelmetProvider>

      <Header />
      <Banner />
      <IndustrialSupplyInner />
      <Footer />
    </div>
  );
}

export default IndustrialSupply;
