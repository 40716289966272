import Header from '../common/Header';
import Footer from '../common/Footer';
import Banner from '../defense/Banner';
import DefenseInner from '../defense/DefenseInner';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Defense() {
  return (
    <div className="defensepage">
      <HelmetProvider>
        <Helmet>
          <title>Expeditionary Support and Services</title>
          <meta name="description" content="IGGTGLOBAL is committed to delivering customized expeditionary support and services to meet your specific requirements." />
          <meta name="keywords" content="expeditionary support, expeditionary services, expeditionary operations" />
        </Helmet>
      </HelmetProvider>

      <Header />
      <Banner />
      <DefenseInner />
      <Footer />
    </div>
  );
}

export default Defense;
