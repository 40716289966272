import Header from '../common/Header';
import Footer from '../common/Footer';
import Banner from '../industries/Banner';
import IndustriesInner from '../industries/IndustriesInner';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Industries() {
  return (
    <div className="industries_page">
      <HelmetProvider>
        <Helmet>
          <title>Global Supplier of Industrial Solutions - Parts, Machinery & Repairs</title>
          <meta name="description" content="IGGTGLOBAL delivers OEM replacement parts and high-quality maintenance material in various industries helping operate flawlessly." />
          <meta name="keywords" content="industries requirement, on-demand industries supplies" />
          <script type="text/javascript" src={ process.env.REACT_APP_BASE_URL + "/assets/js/industries-canvas.js" }></script>
        </Helmet>
      </HelmetProvider>

      <Header />
      <Banner />
      <IndustriesInner />
      <Footer />
    </div>
  );
}

export default Industries;
