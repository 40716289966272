import Header from '../common/Header';
import Footer from '../common/Footer';
import Banner from '../contact/Banner';
import Career from '../contact/Career';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Contact() {
  return (
    <div className="Contact">
      <HelmetProvider>
        <Helmet>
          <title>Contact us - IGGTGLOBAL General Trading Company</title>
          <meta name="description" content="Contact IGGTGLOBAL for inquiries regarding demand and supplies, logistics solutions, career or other queries. Call +971 4 3999 211." />
          <meta name="keywords" content="contact us, career" />
          <script type="text/javascript" src={ process.env.REACT_APP_BASE_URL + "/assets/js/text-animation.js" }></script>
        </Helmet>
      </HelmetProvider>

        <Header />
        <Banner />
        <Career />
        <Footer />
    </div>
  );
}

export default Contact;
