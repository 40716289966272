function Banner() {
    return (
        <div className="banner nuclear">
            <div className="centerbox">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <h1>Nuclear</h1>
                        </div>
                    </div>
                </div>
                <div className="fixedlogo">
                    <a href={ process.env.REACT_APP_BASE_URL + "/"}><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/logo.png" } alt="logo" /></a>
                </div>
            </div>
            <div className="star-particle">
                <div id="stars"></div>
                <div id="stars2"></div>
                <div id="stars3"></div>
            </div>
        </div>
    );
}

export default Banner;