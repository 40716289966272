function CultureInner() {
    return (
        
        <div className="cultureinner innersection">
            <section className="ourstory" id="ourstory">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mb-5">
                            <h3 className="wow fadeInUp" data-wow-duration="2s">Our Story</h3>
                            <p className="wow fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">Every day, we drive to our 12000SQF Facility in Al Quoz, the Industrial Heart of Dubai, hoping to do just two things, Source everything that our Clients request and Offer the best Value Proposition in terms of Service, Price, Quality and Lead time. It was true when we started Ismail Gheewala General Trading LLC in 2009 and it’s just as true today.</p>
                            <p className="wow fadeInUp" data-wow-duration="2s" data-wow-delay=".4s">From the very onset Ismail Gheewala General Trading LLC set out to be a different kind of a company, one that not only focused on the Product but also Professional service, the ease of Ordering, the Quality of the Product, the After Sales, the Global Sourcing Expertise and the 24 hour On-Call Response. It is not unusual for our Clients to call us after office hours Requesting Assistance for Plant Emergencies, in fact there is not a single week where we haven’t gone Above and Beyond our roles to ensure the Client’s ongoing projects are not affected by the Supply Chain hurdles that usually and routinely affect Operations.</p>
                            <p className="wow fadeInUp" data-wow-duration="2s" data-wow-delay=".6s">Until 2013, we had a small team supporting our operations and that figure quickly grew supported by setting up a Showroom in Deira and another Showroom in Al Quoz, by 2016 we started our Technical Services Company, supported by our Clients and the Goodwill we enjoyed within our sectors it propelled us to further growth and Opportunities. We were encouraged to set up our Abu Dhabi operations in 2019 with a Big Team, 3 International Offices and 65 Global Clients extending support to our new Enterprise.</p>
                            <p className="wow fadeInUp" data-wow-duration="2s" data-wow-delay=".8s">The Credit to our Success goes primarily to the way we conduct our Business and the effort of our People, We make sure everything we do is closely examined through the microscope of Ethics and Fair Business Practices – from our Commitment to the Highest Quality Service in the UAE and all other places we work, to the way we engage with our Clients and Vendors to do business responsibly Our Success and Longevity were not apparent from the start. We tried. We failed. We tried something unique again. Innovation and Perseverance drove our team, and it continues to drive us today.</p>
                            <p className="wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">Our Growth Story has only started…</p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <div className="outer-circle-container">
                                <div className="circlebox wow bounceInUp" data-wow-duration="2s" data-wow-delay=".2s">
                                    <p>Founded in</p>
                                    <h3>2009</h3>
                                </div>
                                <div className="circlebox wow bounceInUp" data-wow-duration="2s" data-wow-delay=".4s">
                                    <p>Global Clients</p>
                                    <h3>65</h3>
                                </div>
                                <div className="circlebox wow bounceInUp" data-wow-duration="2s" data-wow-delay=".6s">
                                    <p>Countries</p>
                                    <h3>22</h3>
                                </div>
                                <div className="circlebox wow bounceInUp" data-wow-duration="2s" data-wow-delay=".8s">
                                    <p>Unique Products</p>
                                    <h3>500000+</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="socialimpact">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4 alignself mb-5 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".3s">
                            <h3>SOCIAL IMPACT</h3>
                            <h2><span>AT IGGTGLOBAL,</span> WE WORK WITH PURPOSE.</h2>
                        </div>
                        <div className="col-xl-8 alignself wow fadeInUp" data-wow-duration="2s" data-wow-delay=".6s">
                            <p>We are using our Business as a Force for Good and Building a Safer, more Sustainable and Inclusive Company and Community for all.</p>
                            <p>As the World is faced with the unprecedented impact of the Pandemic &amp; Economic Slowdown, Ismail Gheewala General Trading LLC stands with the Global Community in support to limit the consequences and ease the related Economic Hardships faced by the Communities in which we live and work. Guided by its Family Values and a Spirit of Giving, the Company has made Generous Contributions to support the Well-Being of our Communities.</p>
                            <p>At Iggtglobal, giving back to the Community is embedded within our Company Values. We believe in taking a Hands-On approach to Community Service by encouraging our team members to actively participate in our Charity efforts. Our team is proud to have supported a variety of local and International efforts over the years, most of our efforts center around Food Security, Medical &amp; Pharma Care, Child Nutrition and Education in Africa and South Asia.</p>
                            <p>Our Company annually sponsors Charity Golf Tournaments and Community Sports Tournaments to raise Funds and Awareness to improve lives, since our inception every year we have donated a Portion of our Revenue to Good Causes.</p>
                            <p>This Accomplishment would not have been possible without the on-going support from our Team, our Clients and our Business Associates in the Communities we share.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ourleadership">
                <div className="container-fluid">
                    <div className="row justify-content-center mb-5">
                        <div className="col-xl-9 text-center">
                            <h3 className="wow zoomIn" data-wow-duration="2s" data-wow-delay=".5s">Our Leadership</h3>
                            <p className="wow zoomIn" data-wow-duration="2s" data-wow-delay=".7s">We’re proud to be a Diverse, Unique team united by Values including Openness, Positivity, and Drive that stems from our Leadership, No matter how much our team grows, we shall always remain the Iggtglobal Family.</p>
                            <p className="wow zoomIn" data-wow-duration="2s" data-wow-delay=".9s">The Iggtglobal team loves the tasks we perform and take great Pride in doing it well, At Iggtglobal, you’ll find that you meet Great People who tend to stick around. We’re Proud of the fact that our Team has grown with most Senior Members staying.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-xl-3 order_1 alignself">
                            <div className="avatar-box wow rotateInDownLeft" data-wow-duration="2s">
                                <div className="imgbox"></div>
                                <p>Ismail Gheewala</p>
                            </div>
                        </div>
                        <div className="col-lg-8 col-xl-9 order_2 alignself">
                            <div className="avatar-content wow fadeInRight" data-wow-duration="2s" data-wow-delay=".25s">
                                <p>Ismail blends his Workhorse Genes that come inherently built with a passion for Improvisation and an Undying Urge for living up to his commitment of answering 3 am calls on the second ring, His 30 years in the Middle East have given him enough time to create several multimillion Dollar Enterprises, many of which he manages today.</p>
                                <p>Ismail not only is the Founder of our Company, he is a Large Hearted Father Figure, and He is an Accomplished Businessman par Excellence, an Advisor to many on Finance, an Expert at taking Precision Risks. He spends most working days and holidays on his phone and we genuinely believe that he only listens to you when you talk Business or Food.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 col-xl-9 order_2 alignself">
                            <div className="avatar-content wow fadeInLeft" data-wow-duration="2s">
                                <p>One Head, many Hats, Ali has a natural desire to Research, Innovate and Solve Complex Problems that rise like the sun daily. His unique Skill Set and Vision has shaped our Company and continues to drive its Growth.</p>
                                <p>As Co-founder, Ali is Patient, Disciplined and Fanatical about building the operations of our Company with Good People &amp; Strong Systems, all driven by his desire to move to the Mountains soon and Retire in Peace. When he’s not concocting new Business Strategies for our expansion operations in Foreign Countries, you’re likely to find him on a Tennis Court, Running on a Road or at Home Cooking.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-xl-3 order_1 alignself">
                            <div className="avatar-box wow rotateInDownRight" data-wow-duration="2s" data-wow-delay=".25s">
                                <div className="imgbox"></div>
                                <p>Aliasgar Chandra</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default CultureInner;