function CoolingTowersInner() {
    return (
        <div className="cooling-towers-inner innersection">

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".1s">
                            <p>IGGT Global is a one-of-a-kind blend of expertise, experience, and consistency. We specialize in providing custom-built water treatment services for boiler, industrial cooling towers, and process water systems. We assist our customers in reducing energy, water, and maintenance costs while ensuring that these vital systems operate safely and reliably.</p>
                            <p>Ismail Gheewala General Trading LLC (IGGT Global) offers the knowledge and commitment to assist in any water-related issue. We maintain cutting-edge research facilities and well-equipped analytical laboratories. Our industrial cooling tower supply and cooling tower parts are made following ISO 9001 standards.</p>
                            <p>Our field representatives are well-trained and backed by some of the industry's most knowledgeable water treatment experts. Our cooling tower annual maintenance enables us to provide customized water treatment solutions to improve your bottom line.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
                            <h2>What are industrial cooling towers?</h2>
                            <p>Industrial cooling towers remove excess heat from water used in industrial processes and discharge it into the surrounding biosphere. Surprisingly, there are numerous methods for accomplishing this goal. Because these devices operate in such a wide range of ways, they are divided into several divisions.</p>
                            <p>Cooling tower designs are divided into three categories based on how water or air passes through them. Crossflow, counterflow and hyperbolic are examples of these types. In the next section are details on the types of cooling towers.</p>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-md-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".3s">
                            <h3>What are cooling towers and types?</h3>
                            <p>A cooling tower is a specialized heat exchanger that brings air and water into direct contact to lower the temperature of the water. A small amount of water evaporates, as a result, lowering the temperature of the water cycle through the tower.</p>
                            <h3>The Different Types Of Cooling Towers</h3>
                            <p>Not all towers are appropriate for every use. Cooling towers can be built and manufactured in various styles and sizes.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="support-outerbox">
                <div className="row">
                    <div className="col-lg-6 graybg wow fadeInUp" data-wow-duration="2s" data-wow-delay=".4s">
                        <h3>Cooling Towers with Crossflow</h3>
                        <p>A splash fill is used in crossflow cooling towers to allow in-flowing air to move in a horizontal direction over the stream of water from the upper reservoirs. Crossflow systems are among the more expensive pieces of equipment, but they are also among the easiest to maintain. These cooling systems, however, are more susceptible to frost than others.</p>
                    </div>
                    <div className="col-lg-6 whitebg wow fadeInUp" data-wow-duration="2s" data-wow-delay=".5s">
                        <h3>Cooling Towers with Reverse Flow</h3>
                        <p>As water flows down from the reservoir above, the in-flowing air travels in a vertical path over the splash fill in a counterflow system. Crossflow systems are often larger than counterflow systems. Because more energy is required to pump the air upward against the down-flowing water, these cooling towers are more expensive.</p>
                    </div>
                    <div className="col-lg-6 whitebg order_2 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".6s">
                        <h3>Based on the position of fans:</h3>
                        <ol>
                            <li>Cooling Towers with Induced Draft</li>
                            <li>Cooling Towers with Forced Draft</li>
                        </ol>
                    </div>
                    <div className="col-lg-6 graybg order_1 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".7s">
                        <h3>Based on the cooling tower installation:</h3>
                        <ol>
                            <li>Factory assembled towers</li>
                            <li>Field-erected towers</li>
                        </ol>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".8s">
                            <h3>How does an industrial cooling tower work?</h3>
                            <p>Cooling towers are recirculating water systems that remove heat from industrial processes and building air conditioning equipment. Facility owners and operators can better operate and maintain their cooling tower systems if they understand how they work.</p>
                            <p>The cooling tower, recirculating pump, and heat exchanger are the three main <a class="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/industrial-supply" }>industrial supplies</a>/components of a cooling system. Water absorbs heat before passing through a heat exchanger. The water is dispersed around the cooling tower's top, where air passes over the warm water, causing some of it to evaporate.</p>
                            <p>The heat from the water stream is transferred to the air stream, boosting the temperature and relative humidity of the air to 100%. After then, the warm air is released into the sky, resulting in a cool water stream. The basin's cooled water is then fed back through a heat exchanger, and the cycle begins again.</p>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-md-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".9s">
                            <h3>What is the role of louvers in a cooling tower?</h3>
                            <p>The <a class="btn-read-more hoverlink" href={ process.env.REACT_APP_BASE_URL + "/blogs/cooling-tower-air-inlet-louvers/" } >Air Inlet Louvers</a> prevent sunlight from entering the cooling tower basin. The use of air inlet louvers assists in the control of algae growth. Controlling the light and minimizing algae growth are two ways to save money on chemicals.</p>
                            <p>The Air Inlet Louvers reduce the quantity of splash-out from the cooling tower, which reduces the amount of water and chemicals needed to keep the cooling tower in good working order.</p>
                            <p>If not cared for and maintained, air inlet louvers in cooling towers with counterflow can get scaled. The effectiveness of the cooling tower function will decline due to this.</p>
                            <p>The amount of airflow to the unit is reduced by scaled louvers, limiting its efficiency.</p>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-md-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
                            <h3>Why Choose IGGT Global?</h3>
                            <p>IGGT Global Service in Brief</p>
                            <ul className="ulList">
                                <li><b>Assistance with handling and installation:</b> skilled technicians assist with procedures such as machine handling and cooling tower installation.</li>
                                <li><b>Start-up assistance:</b> qualified technicians to assist with all start-up activities and verify the essential parameters (absorption and pressures of the circuit).</li>
                                <li><b>Extended warranty:</b> For items that are not susceptible to normal wear, the regular guarantee will be extend*.</li>
                                <li><b>Cooling tower maintenance/upgrades:</b> checks are conducted on time to maintain your plant's effective cooling, optimal performance, and maximum productivity, especially during the warmest times of the year.</li>
                                <li><b>Classes and technical training:</b> courses for employees to learn how to service and operate the machine appropriately.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}
export default CoolingTowersInner;