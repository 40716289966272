function IndustrialSupplyInner() {
    return (
        <div className="industrial-supply-inner innersection">

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
                            <p>Over the last Decade, our Industrial Distribution capabilities have experienced substantial growth, driven by implementing quality systems at Iggtglobal. Our Engineering and Design capabilities have developed correspondingly, enabling us to support our clients with custom solutions to their complex Industrial requirements. Factories that manufacture Industrial Products and Solutions have become more aggressive over the last Decade, leading to a spate of Innovative Cost &amp; Time-Saving Solutions. The drive to become more Creative, Innovative and staying at the forefront of these Innovations so they can be effectively utilized by our clients is of paramount importance at Iggtglobal.</p>
                            <p>Iggtglobal is far more than a simple Trading Enterprise or Mover of Boxes, we are a team of Sourcing Experts armed with the specialist knowledge and technical expertise which enables our clients to solve their Plant Maintenance, Operations, Repairs, Manufacturing and Processing challenges. The ability to source everything you need with a single purchase order can be a key advantage in running a smooth operation, bringing additional economic and logistic benefits commensurate with partnering via a trusted single source.</p>
                            <p>By working with us on your strategic sourcing, you immediately have the expertise on hand to locate those hard-to-find items that may have been hidden behind a different SKU or Localized Part Number, identified under a new model number for the same product, or simply discontinued. And if what you need is simply to find and deliver readily available supplies and products to your order, we can do that too.</p>
                            <p>Designed to optimize your supply chain, our Master Agreement program designates an Account Manager to manage and monitor the inventory levels stocked at our facilities. With our efficient stocking services, you no longer have to worry about running out of inventory.</p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="engineer-services">
                                <div className="inner-txt wow fadeInUp" data-wow-duration="2s">
                                    <p><span className="large">Vendor Managed Inventory (VMI)</span> is an emerging focus area within Iggtglobal. We have launched an innovative Pilot Program where our team has installed Industrial Vending Machines to provide Point-of-Use Inventory Management for Industrial and Safety Supplies that our clients can depend on. These machines allow for more Control and Security, and more accurate tracking of Inventory and Budgets. VMI also helps manage Consumables Expenditures while giving Employees real-time access to a wide variety of high-use products, including:</p>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay=".2s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/hardware.png" } alt="Hardware" /></div>
                                    <h4>Hardware</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay=".4s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/plumbing.png" } alt="Plumbing" /></div>
                                    <h4>Plumbing</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay=".6s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/labsupplies.png" } alt="Lab Supplies" /></div>
                                    <h4>Lab Supplies</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay=".8s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/tools.png" } alt="Tools" /></div>
                                    <h4>Tools</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay="1s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/electrical.png" } alt="Electrical" /></div>
                                    <h4>Electrical</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay="1.2s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/metalworking.png" } alt="Metalworking" /></div>
                                    <h4>Metalworking</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay="1.4s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/conveyorsystems.png" } alt="Conveyor Systems" /></div>
                                    <h4>Conveyor Systems</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay="1.6s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/wireropes.png" } alt="Wire Ropes" /></div>
                                    <h4>Wire Ropes</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center mt-5">
                            <h2 className="heading txtanime">Iggtglobal: Where Our People make a difference</h2>
                        </div>
                    </div>
                </div>
            </section>
            

            <section className="blackbg">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <p className="wow fadeInUp" data-wow-duration="2s" data-wow-delay=".2s"><span className="orangetxt">Quality, Innovation, and Price</span> are obvious factors in business success. Iggtglobal’s clients demand more! Our Clients expectations include a great customer experience incorporating High Quality, Innovative products at competitive price points.</p>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <p className="wow fadeInUp" data-wow-duration="2s" data-wow-delay=".4s"><span className="orangetxt">Multi-Brand approach</span>, Customization and Rapid Delivery along with our product knowledge ensure that when partnering with Iggtglobal your Operations will be presented with a plethora of options enabling selection of the Best Value Proposition for your operations, Iggtglobal’s Manufacturer partnerships do not limit the scope of choices that we can offer.</p>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <p className="wow fadeInUp" data-wow-duration="2s" data-wow-delay=".6s"><span className="orangetxt">Subject Matter Experts</span> who have dedicated their efforts and have been an Integral part of our Team for more than a Decade, their expertise provides keen insight into what your Operations require, when it will need it and how it should be delivered.</p>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <p className="wow fadeInUp" data-wow-duration="2s" data-wow-delay=".8s"><span className="orangetxt">Our Ambition</span> is to transform the Delivery of Industrial Supplies and Solutions across our core Areas of Operation for our Clients. This is accomplished by focusing on our Unique Client relationship, our Differentiated Technical Expertise and our Engaged, Entrepreneurial workforce.</p>
                        </div>
                    </div>
                </div>
            </section>                  

        </div>
    );
}
export default IndustrialSupplyInner;