function NuclearInner() {
    return (

        <div className="nuclearinner innersection">
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 wow fadeInUp" data-wow-duration="2s">
                            <p>As a NASL (Nuclear Approved Suppliers List) Company, Iggtglobal is acknowledged for supplying High Quality Equipment, Components and Supplies for the Nuclear Power facilities that require high standards of quality. Our team is a priority supplier for a broad range of the Power Plant Equipment and Applications. While we have a large portfolio of products and components for nearly every power generating industry, Nuclear Power is extremely regulated and required a high level of compliance with every component or product we offer. Our team has passed various audits and continue supporting operations.</p>
                            <p>Nuclear Power is a low-carbon source of electricity that plays a key role in the energy transition that is underway. It supports everyday electricity supply and the continued electrification of society with fossil free power. For decades to come long-term nuclear operations will continue to play an important role in the United Arab Emirates as a climate-neutral, cost-effective source of baseload electricity.</p>
                            <p>The UAE Nuclear Power Industry is at a nascent stage but our team will be supporting its growth every step of the way.</p>
                            <p>Since the very first light bulbs were illuminated using nuclear energy in 1951, around 440 civil Nuclear Power reactors have become operational and currently they provide approximately 11% of the world’s electricity. Demand for energy continues to grow and, worldwide, some 60 Nuclear Power plants are under construction. While the primary focus of plant owners and operators is maintaining the highest safety standards, increasingly there’s the need to aid global competitiveness by finding ways to reduce costs and improve efficiency.</p>
                            <p>Agreeing upon an approved product that is manufactured or available locally for a Nuclear Plant project will have a positive influence on costs and potential downtime. Whether you want to plan a transport program for your Plant’s outsize and heavy parts so that there are no surprises or delays, or analyze available options versus custom design, Iggtglobal’s team of specialists can contribute to your project’s success. We have a team of specialized engineers whose comprehensive perspective helps to quickly and safely develop practical approaches for moving contaminated materials in confined spaces, for example. If you need us to, we will design and build customized equipment for specific locations and complete the work so that it meets the Nuclear Industry’s stringent safety requirements.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="nuclear-innersec">
                <div className="container-fluid centerbox ">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="innersec-box wow fadeInUp" data-wow-duration="2s">
                                        <p>Our Team’s expertise in Mechanical, Civil, Chemical, Metallurgical, Corrosion, Structural, Welding, and Project Management Engineering. These resources can solve many of your Fabrication, Reverse Engineering, Innovation and Technology concerns for plant operations and maintenance projects</p>
                                        <p>Anyone involved in the Nuclear Industry works to the highest safety standards possible. Iggtglobal is no different and our combination of supply chain and engineering expertise, available equipment and experience with Nuclear Facilities mean we meet the industry’s key competitive criteria of saving time and money while always delivering a safe and High-Quality execution.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default NuclearInner;