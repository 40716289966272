function DefenseInner() {
    return (

        <div className="defenseinner innersection">

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 wow fadeInUp" data-wow-duration="2s">
                            <p>From Deployment of Critical Cargo to support Expeditionary Operations to providing the full spectrum of Supplies for Man Camp Operations, Iggtglobal can deliver.</p>
                            <p>Our Decade of experience providing rapid and smooth acquisition of Life Support Equipment for Base Camps in Hostile Environments, our services include Supplies and Materials for Construction &amp; Maintenance of Expeditionary Camps &amp; Facilities across the Middle East, Central Asia and Africa via Government Contracts.</p>
                            <p>Our Network of Support Offices, Qualified Factories and Transport Service providers globally allows us to maintain the Capability &amp; Flexibility required to respond to constantly-shifting Client requirements. Additionally we often include the use of freight consolidation points on various locations, to maximize the use of transport assets as well as reduce costs on OCONUS deliveries.</p>
                            <p>Our Supply Chains are often long and increasingly complex. Globalization has extended supply chains across continents, making it challenging for the Defense Industry to achieve visibility into product and information technology sources, and to achieve accountability of physical goods. Disruptions to the supply chain (whether intentional or unintentional) can impact readiness by delaying delivery of critical resources and equipment, and ultimately result in increased risk to the forces deployed.</p>
                            <p>In Remote Locations, Iggtglobal provides fully-functioning temporary facilities, high-quality workforce housing and comprehensive support services. They're well suited for all sizes of scheduled events, to assist with carefully coordinated construction and renovation projects, and in response to natural disasters &amp; other emergency situations.</p>
                            <p>Every temporary situation – planned and unplanned – is unique. That’s why Iggtglobal is committed to providing temporary support services that are customized to meet your specific requirements.</p>
                            <p>In a sector full of opportunity and unpredictability we support Clients with big ambitions, think faster and act smarter. From the bottom of the ocean to the depths of space and cyberspace, our team combines unique and diverse expertise to allow our clients to confidently master every decisive moment along the way.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="defense-inner-sec">
                <div className="container-fluid centerbox">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="innersec-box wow fadeInUp" data-wow-duration="2s">
                                        <p>In Defense sector our Clients entrust Iggtglobal with helping them achieve and maintain operational and strategic independence in the face of any type of threat to their Supply Chain. Clients rely on our expertise to support their team and make the operation safer, from delivering Covid Diagnostics Tools and Leasing Equipment &amp; Vehicles in the harshest locations known to mankind to delivering solutions that help our clients protect states, cities and critical infrastructures.</p>
                                        <p>By fostering close collaborative partnerships with Technology Partners, Iggtglobal is contributing to the United Arab Emirates Government’s strategy of building enhanced sovereign industrial capability.</p>
                                        <p>Iggtglobal has comprehensive experience in the Defense sector, unparalleled technical expertise, and superior equipment to assist you no matter how remote the location, how extreme the climate, how stringent the timeline, and how unique the conditions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>

    );
  }
  
  export default DefenseInner;