import ErrorPage from '../404/404-page';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function PageNotFound() {
    return (
        <div className="404-page">
            <HelmetProvider>
                <Helmet>
                <title>Iggtglobal – Ismail Gheewala General Trading LLC</title>
                <meta name="description" content="Established in 2009, Iggtglobal has developed strong partnerships with many the world’s leading organizations and support their interests across the Middle East, Central Asia and Africa through the expertise of our team of sourcing, engineering and logistics specialists possessing the training and acumen to develop solutions tailored to each Client’s unique needs." />
                <meta name="keywords" content="industrial products, maintenance supplies, general trading company" />
                </Helmet>
            </HelmetProvider>

            <ErrorPage />
        </div>
    );
}

export default PageNotFound;
