import Repeat from 'react-repeat';

function Banner() {
    return (
        <div className="banner defense">
            <div className="centerbox">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <h1>Expeditionary</h1>
                        </div>
                    </div>
                </div>
                <div className="fixedlogo">
                    <a href={process.env.REACT_APP_BASE_URL + "/"}><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/logo.png" } alt="logo" /></a>
                </div>
            </div>

            <div className="circle-particle">
                <Repeat size={50}>
                    <div className="circle-container">
                        <div className="circle"></div>
                    </div>
                </Repeat>
            </div>
        </div>
    );
}

export default Banner;