function Banner() {
    return (
        <div className="banner industries">
            <div className="centerbox">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <h1>Industries</h1>
                        </div>
                    </div>
                </div>
                <div className="fixedlogo">
                    <a href={ process.env.REACT_APP_BASE_URL + "/"}><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/logo.png" } alt="logo" /></a>
                </div>
            </div>
            <div id="particle-canvas">
                <canvas></canvas>
            </div>
        </div>
    );
}

export default Banner;