import Header from '../common/Header';
import Footer from '../common/Footer';
import Banner from '../culture/Banner';
import CultureInner from '../culture/CultureInner';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Culture() {
  return (
    <div className="culturepage">
      <HelmetProvider>
        <Helmet>
          <title>Our Culture - Ismail Gheewala General Trading LLC</title>
          <meta name="description" content="Founded in 2009, our network consists of reliable global clients spread across 22 countries dealing with thousands of unique products in various sectors." />
          <meta name="keywords" content="company culture, company values" />
        </Helmet>
      </HelmetProvider>

      <Header />
      <Banner />
      <CultureInner />
      <Footer />
    </div>
  );
}

export default Culture;
