function ErrorPage() {
    return (
        <div className="error-page">
            <div className="centerbox">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <h1>404</h1>
                            <h3>Oh No! This page could not be found</h3>
                            <a className="btn-read-more hoverlink orangetxt" href={process.env.REACT_APP_BASE_URL + "/"}>Back To Home<div></div></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;