import Repeat from 'react-repeat';

function Banner() {
    return (
        <div className="banner culture">
            <div className="centerbox">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <h1>culture</h1>
                        </div>
                    </div>
                </div>
                <div className="fixedlogo">
                    <a href={process.env.REACT_APP_BASE_URL + "/"}><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/logo.png" } alt="logo" /></a>
                </div>
            </div>

            <div className="ripple-particle">
                <div className="ripple-particle-inner">
                    <div className="ripple-background">
                        <div className="circle xxlarge shade1"></div>
                        <div className="circle xlarge shade2"></div>
                        <div className="circle large shade3"></div>
                        <div className="circle mediun shade4"></div>
                        <div className="circle small shade5"></div>
                    </div>
                    <Repeat size={25}>
                        <div className="particles"></div>
                    </Repeat>
                </div>
            </div>
        </div>
    );
}

export default Banner;