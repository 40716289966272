function Services() {
    return (

      <div className="services section" id="home_services">

        <div className="dots-particle">
          <div className="dots">
            <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
          </div>
        </div>

        <div className="container-fluid">
          <div id="desk-view">
              <div id="ServicesList" className="tabbed-content tabs-side">
                <nav id="outer_tab2" className="tabs">
                    <ul>
                      <li><a id="industries" href="#" onClick={e => e.preventDefault()} className="wow fadeInLeftBig active" data-wow-duration="1.5s">Industry</a></li>
                      <li><a id="defense" href="#" onClick={e => e.preventDefault()} className="wow fadeInLeftBig" data-wow-duration="1.5s" data-wow-delay=".25s">Expeditionary</a></li>
                      <li><a id="nuclear" href="#" onClick={e => e.preventDefault()} className="wow fadeInLeftBig" data-wow-duration="1.5s" data-wow-delay=".5s">Nuclear</a></li>
                      <div id="active-tab-overlay" className="wow fadeInLeftBig industries-active" data-wow-duration="1.5s"></div>
                    </ul>
                </nav>
                <div className="outer_tab_content">
                    <section id="industries_tab" className="outer_tab item wow fadeInUpBig active" data-wow-duration="2s" data-title="Industries">
                      <div className="item-content">
                          <div className="row">
                            <div className="col-lg-12 mb-3">
                              <img className="w-100" src={ process.env.REACT_APP_BASE_URL + "/assets/img/industry-service.png" } alt="Industries Service" />
                            </div>
                            <div className="col-lg-12">
                                <p>We are a Leading Supplier of Industrial Solutions, Products and Engineered Equipment packages, our Clients are on the front line of the World's Toughest Industrial challenges.</p>
                                <p>Providing High-Quality Parts and Maintenance materials on time can make the difference between Success and Failure. Iggtglobal delivers OEM Replacement Parts when promised to help you Minimize Maintenance Downtime and Prevent Future Breakdowns. Our Clients count on Iggtglobal to Stock Critical items for planned Maintenance and Emergency Repairs. The fact our Clients regularly call us past Midnight for Plant Breakdowns or Support speaks volumes of our Credibility in the Industrial Sector.</p>
                                <a className="btn-read-more hoverlink orangetxt" href={process.env.REACT_APP_BASE_URL + "/industries"}>Read More</a>
                            </div>
                          </div>
                      </div>
                    </section>
                    <section id="defense_tab" className="outer_tab item wow fadeInUpBig" data-wow-duration="2s" data-title="Defense">
                      <div className="item-content">
                          <div className="row">
                            <div className="col-lg-12">
                              <img className="w-100" src={ process.env.REACT_APP_BASE_URL + "/assets/img/defense-service.png" } alt="Expeditionary Service" />
                            </div>
                            <div className="col-lg-12">
                                <p>From Deployment of critical cargo to support Expeditionary Operations to providing the full spectrum of supplies for man camp operations, Iggtglobal can deliver.</p>
                                <p>Our Decade of experience providing rapid and smooth acquisition of Life Support Equipment for Base Camps in Hostile Environments, our services include supplies and materials for construction &amp; maintenance of expeditionary camps &amp; facilities across the Middle East, Central Asia and Africa via government contracts.</p>
                                <p>Our network of support offices, qualified suppliers and transport service providers globally allows us to maintain the capability &amp; flexibility required to respond to constantly-shifting client requirements. Additionally we often include the use of freight consolidation points on various locations, to maximize the use of transport assets as well as reduce costs on OCONUS deliveries.</p>
                                <a className="btn-read-more hoverlink orangetxt" href={process.env.REACT_APP_BASE_URL + "/expeditionary"}>Read More</a>
                            </div>
                          </div>
                      </div>
                    </section>
                    <section id="nuclear_tab" className="outer_tab item wow fadeInUpBig" data-wow-duration="2s" data-title="Nuclear">
                      <div className="item-content">
                          <div className="row">
                            <div className="col-lg-12">
                              <img className="w-100" src={ process.env.REACT_APP_BASE_URL + "/assets/img/nuclear-service.png" } alt="Nuclear Service" />
                            </div>
                            <div className="col-lg-12">
                                <p>As a NASL (Nuclear Approved Suppliers List) Company, Iggtglobal is acknowledged for supplying High Quality Equipment, Components and Supplies for the Nuclear Power facilities that require high standards of quality. Our team is a priority supplier for a broad range of the Power Plant Equipment and Applications. While we have a large portfolio of products and components for nearly every power generating industry, Nuclear Power is extremely regulated and required a high level of compliance with every component or product we offer. Our team has passed various audits and continue supporting operations.</p>
                                <p>Nuclear Power is a low-carbon source of electricity that plays a key role in the energy transition that is underway. It supports everyday electricity supply and the continued electrification of society with fossil free power. For decades to come long-term nuclear operations will continue to play an important role in the United Arab Emirates as a climate-neutral, cost-effective source of baseload electricity.</p>
                                <a className="btn-read-more hoverlink orangetxt" href={process.env.REACT_APP_BASE_URL + "/nuclear"}>Read More</a>
                            </div>
                          </div>
                      </div>
                    </section>
                </div>
              </div>
          </div>

          <div id="mob-view">
              <div className="nav-wrap">
                <nav className="acnav" role="navigation">
                    <ul className="acnav__list acnav__list--level1">
                      <li className="has-children">
                          <div className="acnav__label">Industry</div>
                          <ul className="acnav__list acnav__list--level2">
                            <li>
                                <div className="row">
                                  <div className="col-md-12 mb-3">
                                    <img className="w-100" src={ process.env.REACT_APP_BASE_URL + "/assets/img/industry-service.png" } alt="Industries Service" />
                                  </div>
                                  <div className="col-md-12">
                                      <p>We are a Leading Supplier of Industrial Solutions, Products and Engineered Equipment packages, our Clients are on the front line of the World's Toughest Industrial challenges.</p>
                                      <p>Providing High-Quality Parts and Maintenance materials on time can make the difference between Success and Failure. Iggtglobal delivers OEM Replacement Parts when promised to help you Minimize Maintenance Downtime and Prevent Future Breakdowns. Our Clients count on Iggtglobal to Stock Critical items for planned Maintenance and Emergency Repairs. The fact our Clients regularly call us past Midnight for Plant Breakdowns or Support speaks volumes of our Credibility in the Industrial Sector.</p>
                                      <p>By working with us on your Strategic Sourcing, you immediately have the expertise on hand to locate those Hard-to-Find items that may have been hidden behind a different SKU or Localized Part Number, identified under a new model number for the same product, or simply discontinued. And if what you need is simply to find and deliver readily available supplies and products to your order, we can do that too.</p>
                                      <a className="btn-read-more hoverlink orangetxt" href={process.env.REACT_APP_BASE_URL + "/industries"}>Read More</a>
                                  </div>
                                </div>
                            </li>
                          </ul>
                      </li>
                      <li className="has-children">
                          <div className="acnav__label">Expeditionary</div>
                          <ul className="acnav__list acnav__list--level2">
                            <li>
                                <div className="row">
                                  <div className="col-md-12 mb-3">
                                    <img className="w-100" src={ process.env.REACT_APP_BASE_URL + "/assets/img/defense-service.png" } alt="Expeditionary Service" />
                                  </div>
                                  <div className="col-md-12">
                                      <p>From Deployment of critical cargo to support Expeditionary Operations to providing the full spectrum of supplies for man camp operations, Iggtglobal can deliver.</p>
                                      <p>Our Decade of experience providing rapid and smooth acquisition of Life Support Equipment for Base Camps in Hostile Environments, our services include supplies and materials for construction &amp; maintenance of expeditionary camps &amp; facilities across the Middle East, Central Asia and Africa via government contracts.</p>
                                      <p>Our network of support offices, qualified suppliers and transport service providers globally allows us to maintain the capability &amp; flexibility required to respond to constantly-shifting client requirements. Additionally we often include the use of freight consolidation points on various locations, to maximize the use of transport assets as well as reduce costs on OCONUS deliveries.</p>
                                      <a className="btn-read-more hoverlink orangetxt" href={process.env.REACT_APP_BASE_URL + "/expeditionary"}>Read More</a>
                                  </div>
                                </div>
                            </li>
                          </ul>
                      </li>
                      <li className="has-children">
                          <div className="acnav__label">Nuclear</div>
                          <ul className="acnav__list acnav__list--level2">
                            <li>
                                <div className="row">
                                  <div className="col-md-12 mb-3">
                                    <img className="w-100" src={ process.env.REACT_APP_BASE_URL + "/assets/img/nuclear-service.png" } alt="Nuclear Service" />
                                  </div>
                                  <div className="col-md-12">
                                      <p>As a NASL (Nuclear Approved Suppliers List) Company, Iggtglobal is acknowledged for supplying High Quality Equipment, Components and Supplies for the Nuclear Power facilities that require high standards of quality. Our team is a priority supplier for a broad range of the Power Plant Equipment and Applications. While we have a large portfolio of products and components for nearly every power generating industry, Nuclear Power is extremely regulated and required a high level of compliance with every component or product we offer. Our team has passed various audits and continue supporting operations.</p>
                                      <p>Nuclear Power is a low-carbon source of electricity that plays a key role in the energy transition that is underway. It supports everyday electricity supply and the continued electrification of society with fossil free power. For decades to come long-term nuclear operations will continue to play an important role in the United Arab Emirates as a climate-neutral, cost-effective source of baseload electricity.</p>
                                      <a className="btn-read-more hoverlink orangetxt" href={process.env.REACT_APP_BASE_URL + "/nuclear"}>Read More</a>
                                  </div>
                                </div>
                            </li>
                          </ul>
                      </li>
                    </ul>
                </nav>
              </div>
          </div>
        </div>

      </div>

    );
  }
  
  export default Services;