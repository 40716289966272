function Header() {
    return (
        <div>
            <div className="header">
                <div className="container-fluid">
                    <div className="hrader-inner">
                        <div className="logo">
                            <a href={process.env.REACT_APP_BASE_URL + "/"}><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/logo.png" } alt="logo" /></a>
                        </div>
                        <div className="menu">
                            <button className="menu-icon">
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>            

            <div className="slide-menu">
                <button className="close-icon">
                    <span></span>
                    <span></span>
                </button>
                <ul>
                    <li className="nav-item"><a className="nav-link active" href={process.env.REACT_APP_BASE_URL + "/"}>Home</a></li>
                    <li className="nav-item dropdown">
                        <a href={process.env.REACT_APP_BASE_URL + "/"} onClick={e => e.preventDefault()} className="nav-link dropdown-toggle" data-toggle="dropdown">Capabilities <b className="caret"><span>❮</span></b></a>
                        <ul className="dropdown-menu">
                            <li className="nav-item"><a className="nav-link" href={process.env.REACT_APP_BASE_URL + "/our-mission"}>Mission Support</a></li>
                            <li className="nav-item"><a className="nav-link" href={process.env.REACT_APP_BASE_URL + "/engineering-services"}>Engineering Services</a></li>
                            <li className="nav-item"><a className="nav-link" href={process.env.REACT_APP_BASE_URL + "/industrial-supply"}>Industrial Supply</a></li>                            
                            <li className="nav-item"><a className="nav-link" href={process.env.REACT_APP_BASE_URL + "/industrial-cooling-towers"}>Industrial Cooling Towers</a></li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                        <a href={process.env.REACT_APP_BASE_URL + "/"} onClick={e => e.preventDefault()} className="nav-link dropdown-toggle" data-toggle="dropdown">About <b className="caret"><span>❮</span></b></a>
                        <ul className="dropdown-menu">
                            <li className="nav-item"><a className="nav-link" href={process.env.REACT_APP_BASE_URL + "/culture"}>Culture</a></li>
                            <li className="nav-item"><a className="nav-link" href={process.env.REACT_APP_BASE_URL + "/why-choose-us"}>Why IGGTGlobal?</a></li>
                        </ul>
                    </li>
                    <li className="nav-item"><a className="nav-link" href={process.env.REACT_APP_BASE_URL + "/blogs/"}>Blogs</a></li>
                    <li className="nav-item"><a className="nav-link" href={process.env.REACT_APP_BASE_URL + "/contact-us"}>Contact</a></li>
                </ul>
            </div>    
            
        </div>
    );
}

export default Header;