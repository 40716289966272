function Career() {
    return (
      <div className="contactinner innersection">
        
        <section>
          <div className="container-fluid">
              <div className="row mb-5">
                <div className="col-md-12 mb-5 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
                    <p>Reach out to us if you would like more information on our Operations.</p>
                    <p>Feel free to drop by our Office for Coffee or Masala Chai between 1100 Hrs. &amp; 1500 Hrs. and you will find 48 Diligent Squad Members working on Solutions that Support our Clients Globally.</p>
                </div>
                <div className="col-md-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".4s">
                    <p>You can Email us on <a className="orangetxt hoverlink" href="mailto:sales@Iggtglobal.com">sales@Iggtglobal.com</a> or Old Fashioned phone calls on <a className="hoverlink" href="tel:+971 4 3999 211">+971 4 3999 211</a></p>
                </div>
              </div>
              <div className="row mt-3 pt-5">
                <div className="col-md-12">
                    <h2 className="heading txtanime">At Iggtglobal, We are Family.</h2>
                </div>
              </div>
          </div>
        </section>

        <section className="graybg careerbox">
          <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                    <h3 className="col-md-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".1s">CAREER</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
                    <h5>We Value You</h5>
                    <p>We take Care of our Team in more ways than one. We offer Competitive Pay and Benefits, but we always look for ways to Reward those who Stand Out among their Peers and Demonstrate True Dedication, As a part of our Iggtglobal family each member receives an Equity Package in the Company as Additional Compensation, our Benefits Package also include Premium Medical Coverage and endless servings of our Famous Masala Chai.</p>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-12 col-xl-5">
                    <div className="width75">
                      <div className="mb-5 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".4s">
                          <h5>Our Team</h5>
                          <p>We work with some of the most Interesting People on the Planet, Our Office has a Mountaineer, a Muscleman, a Musician, a Baker and a Poet to name a few, and we are always Looking for People who Enjoy their Work over the week and know how to Unwind.</p>
                      </div>
                      <div className="mb-1 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".6s">
                          <h5>We Play at Work</h5>
                          <p>Our Team Plays at Work – Every Day!!! A Refreshment Hour at the Office can include using our Ping Pong Table to Resolve Disputes, or a Foosball Tournament to Release Stress, Office Lunches, Birthday Parties and Festivities.</p>
                          <p>There are a host of Pressure Busters because we believe that a Healthy &amp; Fresh Mind in the office is Vital to our team’s Satisfaction at Work.</p>
                          <p>At our Company, we have tried to create a Workplace where you feel Welcome and can contribute your Best Work.</p>
                      </div>
                    </div>
                </div>
                <div className="col-md-12 col-xl-7">
                  <img className="wow zoomIn" data-wow-duration="2s" src={ process.env.REACT_APP_BASE_URL + "/assets/img/contact-description.png" } alt="contact-description" />
                </div>
              </div>
          </div>
        </section>

      </div>
    );
  }
  
  export default Career;