import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './pages/Home';
import MissionSupport from './pages/MissionSupport';
import Engineering from './pages/Engineering';
import IndustrialSupply from './pages/IndustrialSupply';
import Defense from './pages/Defense';
import Nuclear from './pages/Nuclear';
import Culture from './pages/Culture';
import Why_IGGT from './pages/Why_IGGT';
import Contact from './pages/Contact';
import Industries from './pages/Industries';
import CoolingTowers from './pages/CoolingTowers';
import PageNotFound from './pages/PageNotFound';

export default function index() {
  return (
    <Router basename={ process.env.REACT_APP_BASE_NAME }>
      <div>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>     
          <Route exact path="/our-mission">
            <MissionSupport />
          </Route>    
          <Route exact path="/engineering-services">
            <Engineering />
          </Route>    
          <Route exact path="/industrial-supply">
            <IndustrialSupply />
          </Route>    
          <Route exact path="/expeditionary">
            <Defense />
          </Route>
          <Route exact path="/nuclear">
            <Nuclear />
          </Route>
          <Route exact path="/culture">
            <Culture />
          </Route>  
          <Route exact path="/why-choose-us">
            <Why_IGGT />
          </Route>
          <Route exact path="/contact-us">
            <Contact />
          </Route>
          <Route exact path="/industries">
            <Industries />
          </Route>
          <Route exact path="/industrial-cooling-towers">
            <CoolingTowers />
          </Route>
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
