import Header from '../common/Header';
import Footer from '../common/Footer';
import Banner from '../engineering/Banner';
import EngineeringInner from '../engineering/EngineeringInner';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Engineering() {
  return (
    <div className="engineeringpage">
      <HelmetProvider>
        <Helmet>
          <title>Engineering Services - IGGTGLOBAL Engineering Services</title>
          <meta name="description" content="Through modern fabrication equipment, our engineering services provide one-stop solutions from manufacturing to replacement parts in the gulf region." />
          <meta name="keywords" content="engineering services, maintenance, installations, supplies" />
        </Helmet>
      </HelmetProvider>

      <Header />
      <Banner />
      <EngineeringInner />
      <Footer />
    </div>
  );
}

export default Engineering;
