function MissionSupportInner() {
    return (
        <div className="mission-support-inner innersection">

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
                            <p>For our Remote Site Clients, we firmly grasp that Time Sensitivity is Part and Parcel of their operations. Iggtglobal has been supporting Expeditionary and Contingency operations for more than a Decade Iggtglobal is highly respected and renowned for operating in diverse project environments, including some of the world’s most Challenging Regions, We deploy Industry-leading expertise, processes, and our vast experience in Hostile Environments to ensure the availability and reliability of our Government and Commercial Clients’ vital Facilities and Equipment in Remote &amp; Austere locations while providing Sustainable Supply Solutions for deployed Personnel, Humanitarian Operations and Disaster Relief around the Middle East, Central Asia and Africa.</p>
                            <p>Trust our team to deliver the most competitive rates, driven by our efficient logistics solutions delivered to your Remote location.</p>
                            <p>We are a Highly Respected and Renowned integrated Remote Site Services Company, operating in more than nine diverse Geographical Locations around the world. We are active in a wide range of project environments, including some of the World’s most Challenging Regions.</p>
                            <p>Our Life-Cycle Maintenance, Repair &amp; Operations (MRO) supply solutions help our Clients meet their short-term goals while maintaining their facilities and systems for future missions.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="support-outerbox">
                <div className="row">
                    <div className="col-lg-6 graybg wow fadeInUp" data-wow-duration="2s" data-wow-delay=".4s">
                        <h2>Unparalleled Expertise</h2>
                        <p>Iggtglobal possessing unparalleled expertise experts in adapting our camp infrastructure and Operation services to meet your unique project requirements and budget targets. Iggtglobal has many years of experience costing out complex projects for our clients.</p>
                        <p>Every temporary situation – planned and unplanned – is unique.</p>
                        <p>Our costing is carefully thought out, eliminating unwanted surprises given our Industry-leading expertise, processes, and our vast experience in Hostile Environments</p>
                    </div>
                    <div className="col-lg-6 whitebg wow fadeInUp" data-wow-duration="2s" data-wow-delay=".6s">
                        <h2>Expeditionary Capabilities</h2>
                        <p>Our expeditionary capabilities include the ability to provide quick-response temporary and mobile Life Support facilities as well as long term and high-comfort facilities to maximize morale.</p>
                        <p>Our Material Management solutions support services through all phases of Maintenance and Operations including Containerized Living, Vehicle Fleet Operations, Potable Water Filtration; Catering &amp; Dining Facility, Plumbing, Electrical, HVAC, Power Generation, Material Handling Heavy Equipment, Waste Treatment and Incinerators.</p>
                    </div>
                    <div className="col-lg-6 whitebg order_2 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".8s">
                        <h2>Remote Site Supply</h2>
                        <p>Iggtglobal Mission Support focuses on Supplies for Remote Site Sustenance while also providing services such as light Construction, Carpentry, Welding, and Painting. Transportation, Vehicle Maintenance Facilities (VMF), Vector Control, Security, and Billeting. Iggtglobal’s Morale Welfare and Recreation (MWR) offerings can include a Gymnasium, Libraries, Event Supplies, Televisions, Laptops and more.</p>
                        <p>We offer long term and short term lease arrangements for Living Units, Non-Tactical Vehicles and Equipment.</p>
                    </div>
                    <div className="col-lg-6 graybg order_1 wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
                        <h2>Procurement Process</h2>
                        <p>As a preferred procurement partner for some of the largest global Corporations, Governments, Organizations, and Institutions operating in Hostile environments, Iggtglobal’s procurement processes are structured with safeguards deliberately incorporated to ensure that all our Vendors, Manufacturers and associates adhere to our Code of Ethics.</p>
                        <p>Through our actions and policies, we commit to the most cost-effective and transparent Mission Support Solutions for our clients, while ensuring absolute quality of product on time.</p>
                    </div>
                </div>
            </section>

            <section className="support-product-line">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".4s">
                            <div className="prod-line-box">
                                <div className="prod-line-img">
                                    <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/product-line.png" } alt="Product Line" />
                                </div>
                                <div className="prod-line-content">
                                    <p>Explore the full range of products we deal into in different sectors of our service. Download our product line to view the full list.</p>
                                    <a href={ process.env.REACT_APP_BASE_URL + "/assets/img/product-line-card.pdf" } className="btn btn-download" download="">DOWNLOAD NOW</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}
export default MissionSupportInner;