import Header from '../common/Header';
import Footer from '../common/Footer';
import BannerSlider from '../home/BannerSlider';
import IndustriesType from '../home/IndustriesType';
import Services from '../home/Services';
import { Helmet, HelmetProvider } from 'react-helmet-async';


function Home() {
  return (
    <div className="Home">
      <HelmetProvider>
        <Helmet>
          <title>Iggtglobal – Ismail Gheewala General Trading LLC</title>
          <meta name="description" content="Established in 2009, Iggtglobal has developed strong partnerships with many the world’s leading organizations and support their interests across the Middle East, Central Asia and Africa through the expertise of our team of sourcing, engineering and logistics specialists possessing the training and acumen to develop solutions tailored to each Client’s unique needs." />
          <meta name="keywords" content="industrial products, maintenance supplies, general trading company" />
        </Helmet>
      </HelmetProvider>

      <div id="fullpage">
        <Header />
        <BannerSlider />
        <IndustriesType />
        <Services />
        <Footer />
      </div>
    </div>
  );
}

export default Home;
