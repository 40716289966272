import Header from '../common/Header';
import Footer from '../common/Footer';
import Banner from '../nuclear/Banner';
import NuclearInner from '../nuclear/NuclearInner';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Nuclear() {
  return (
    <div className="nuclearpage">
      <HelmetProvider>
        <Helmet>
          <title>Nuclear Approved Suppliers - IGGTGLOBAL Supply Chain</title>
          <meta name="description" content="As an NASL approved supplier, we deliver high-quality equipment, components, supplies, and services for operating nuclear reactors at Nuclear Power plants facilities." />
          <meta name="keywords" content="nuclear suppliers, nuclear power plants" />
        </Helmet>
      </HelmetProvider>

      <Header />
      <Banner />
      <NuclearInner />
      <Footer />
    </div>
  );
}

export default Nuclear;
