function EngineeringInner() {
    return (
        <div className="engineeringinner innersection">

            <section>
                <div className="container-fluid">
                    <div className="row mb-5">
                        <div className="col-md-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".2s">
                            <p>Iggtglobal maintains a full range of advanced technology and modern fabrication equipment to perform all services in-house. From design to delivery, we provide one-stop precision manufacturing and align our practices, policies, and production with global standards.</p>
                            <p>Over the decades, manufacturers have produced millions of machine parts and component that sooner or later tend to break down. When the part malfunctions, you want to replace the component, not the whole device. Unfortunately, many older parts are no longer available. Our engineering service makes replacing these parts possible.</p>
                            <p>Producing a custom metal part in a specific desired shape requires specialized fabrication equipment. We use a variety of tools for metal fabrication such as a CNC machine, laser cutting machine, or water-jetting machine. At The Federal Group, we have the materials, machinery, and experience to handle any custom metal fabrication project.</p>
                            <p>Our fabrication services include the building of metal parts by Laser cutting, Water Cutting, Bending, CNC Punching, Shearing, Forming, Hardware Installation, Spot Weld, Mig or Tig Welding, Metal Finishing and Machining requirements. These processes are performed using specialized equipment (often created for a specific application), expert technicians and/or multiple machine operations.</p>
                            <p>Working with Iggtglobal workshop ensures that your parts are made specifically for your application. We have facilities with the latest machining and fabricating equipment. Welders, millers, and CNC machines are just a few examples.</p>
                            <p>Along with the latest equipment, you receive the best materials. If you’re unsure of the best metal or alloy to use for your project, our team has the experience needed to help with your selection. We understand the differences between alloys and how they are affected by various environments and applications.</p>
                            <p>We stock a wide variety of special grades/alloys of steel, aluminum, and stainless steel sheets, plates, tubes, and bar stock to help ensure a quick turnaround for your projects. Our experienced Metal Fabricators can complete most custom metal fabrication projects in two weeks or less. Our goal is to provide the customized metal manufacturing our customers need, all from one metal fabrication shop.</p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <div className="engineer-services">
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay=".1s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/cad-design.png" } alt="cad-design" /></div>
                                    <h4>Cad Design</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay=".2s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/cnc-cutting.png" } alt="cnc-cutting" /></div>
                                    <h4>CNC Cutting</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay=".3s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/welding.png" } alt="welding" /></div>
                                    <h4>Welding</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay=".4s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/lasser-cutting.png" } alt="lasser-cutting" /></div>
                                    <h4>Lasser Cutting</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay=".5s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/assembly-fitting.png" } alt="assembly-fitting" /></div>
                                    <h4>Assembly Fitting</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay=".6s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/bending.png" } alt="bending" /></div>
                                    <h4>Bending</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay=".7s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/gearbox-service.png" } alt="gearbox-service" /></div>
                                    <h4>Gearbox Service</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay=".8s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/manufacturing-components.png" } alt="manufacturing-components" /></div>
                                    <h4>Manufacturing Components</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay=".9s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/obsolete-parts.png" } alt="obsolete-parts" /></div>
                                    <h4>Obsolete Parts</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay="1s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/prototyping.png" } alt="prototyping" /></div>
                                    <h4>Prototyping</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay="1.1s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/pump-repair.png" } alt="pump-repair" /></div>
                                    <h4>Pump Repair</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay="1.2s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/punching.png" } alt="punching" /></div>
                                    <h4>Punching</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay="1.3s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/reverse-engineering.png" } alt="reverse-engineering" /></div>
                                    <h4>Reverse Engineering</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay="1.4s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/value-engineering.png" } alt="value-engineering" /></div>
                                    <h4>Value Engineering</h4>
                                </div>
                                <div className="servicebox wow zoomIn" data-wow-duration="2s" data-wow-delay="1.5s">
                                    <div className="imgbox"><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/valve-overhaul.png" } alt="valve-overhaul" /></div>
                                    <h4>Valve Overhaul</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 

            <section className="engineeringbox">
                <div className="container-fluid">
                    <div className="row text-center">
                        <div className="col-xl-4 mb-3 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".4s">
                            <div className="black-cointainer">
                            <h2>Value Engineering</h2>
                            <p>The beauty of Value Engineering solution lies in the analysis of your Engineering Designs – not only for manufacturability, but the lowest total cost – all while still meeting your High-Quality specifications. Value, as defined, is the ratio of function to cost. Therefore, depending on the “value” of the product, the function may be improved or cost reduced</p>
                            <p>With a lens on Manufacturing, we evaluate drawings to see where the biggest impact for improvement is.  This will save you time and money while not compromising the most important part of the puzzle, the quality of your product.  In other words, through value engineering, our Design &amp; Engineering team will ensure your parts are optimized for Fabrication.</p>
                            </div>
                        </div>
                        <div className="col-xl-4 mb-3 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".6s">
                            <div className="black-cointainer">
                            <h2>Reverse Engineering</h2>
                            <p>Reverse Engineering is a process we use to generate a drawing of an existing Components when the original drawings are no longer available or the Manufacturer confirms that the part is Obsolete. We start by taking precise measurements of the workpiece.</p>
                            <p>We can help you turn a Crude Drawing into a Professional Schematic and eventually a finished Product. Our Engineers can and want to help you bring your ideas to life with our Prototyping service.</p>
                            <p>We provide Reverse Engineering services on metal parts including castings, stampings and sub-assemblies. We have successfully helped Industrial clients with metal part re-creation when replacement parts are not available. Our Reverse Engineering process will provide you with an exact replacement of the part you would like to manufacture</p>
                            </div>
                        </div>
                        <div className="col-xl-4 mb-3 wow fadeInUp" data-wow-duration="2s" data-wow-delay=".8s">
                            <div className="black-cointainer">
                            <h2>Engineering Services</h2>
                            <p>Our team can provide a full range of Engineering Services to formulate your idea, a scratch piece of paper, prints or completed engineering files that go into a viable finished product. We take great care to respond quickly to your needs with quality metal parts, delivered on time. Whether your job is a High Volume run or a Prototype, you are assured Flawless Parts at a fair price.</p>
                            <p>Our team of Engineers and Designers leverage their creativity to develop and implement cost effective, intuitive, robust solutions for Structural &amp; Design challenges. From Reinforcing an existing Building Column, to Designing an Obsolete Component or Steel for a Car Shade and Industrial Shed, we have the skill set, knowledge base, and experience to fill your Engineering needs, large or small.</p>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
                            <p>Steel is in our blood. It’s where we started, and it’s at the core of who we are. But over the years, Iggtglobal has developed and acquired the skills and equipment to fabricate just about any anything. Whether you need Stainless Steel Architectural Structure, Specialty Components, or Steel for your next high rise project, we have the Expertise and Craftsmen to meet your challenge. We are known to do what others deem impossible, so much so that our clients include other Steel Fabricators who need additional support. Our state-of-the-art workshop has the capacity and space to take on your toughest challenges.</p>
                            <p>Iggtglobal quality systems are compliant with ISO 9001:2015 and ISO 14001:2015 standards. We do multi-level inspections on all parts during production and conduct final inspection prior to shipping.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}
export default EngineeringInner;