import Header from '../common/Header';
import Footer from '../common/Footer';
import Banner from '../cooling-towers/Banner';
import CoolingTowersInner from '../cooling-towers/CoolingTowersInner';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function CoolingTowers() {
  return (
    <div className="industrial_supply_page">
      <HelmetProvider>
        <Helmet>
          <title>Industrial Cooling Towers, Cooling Tower Supplies & Maintenance</title>
          <meta name="description" content="We carry out from design, and installation to maintenance and upgrades of cooling towers, supplies and parts for industrial cooling towers to operate." />
          <meta name="keywords" content="cooling towers, industrial cooling towers, cooling tower maintenance" />
        </Helmet>
      </HelmetProvider>

      <Header />
      <Banner />
      <CoolingTowersInner />
      <Footer />
    </div>
  );
}

export default CoolingTowers;
