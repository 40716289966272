import Header from '../common/Header';
import Footer from '../common/Footer';
import Banner from '../why-iggt/Banner';
import Why_IGGT_Inner from '../why-iggt/Why_IGGT_Inner';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Why_IGGT() {
  return (
    <div className="whyiggtpage">
        <HelmetProvider>
          <Helmet>
            <title>Work with IGGTGLOBAL</title>
            <meta name="description" content="Family-owned independent company with Global Market reach in the B2B distribution of industrial supplies & services across 12 unique market sectors." />
            <meta name="keywords" content="work with us" />
          </Helmet>
        </HelmetProvider>

        <Header />
        <Banner />
        <Why_IGGT_Inner />
        <Footer />
    </div>
  );
}

export default Why_IGGT;
