function BannerSlider() {
    return (
      <div className="banner_slider section">

        <div id="carousel" className="carousel slide" data-ride="carousel">
            
            <div className="carousel-inner" role="listbox">
                <div className="carousel-item active">
                    <div className="slider slider-1">
                        <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/slide-banner-1.png" } alt="slide-banner-1" />
                    </div>
                    <div className="carousel-caption">
                        <div className="carousel-img">
                            <a href={process.env.REACT_APP_BASE_URL + "/"}><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/logo.png" } alt="logo" /></a>
                        </div>
                        <div className="carousel-content slider-1-txt">
                            <h2>12<span>Diverse</span></h2>
                            <h3>Sectors</h3>
                        </div>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="slider slider-2">
                        <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/slide-banner-2.png" } alt="slide-banner-2" />
                    </div>
                    <div className="carousel-caption">
                        <div className="carousel-img">
                            <a href={process.env.REACT_APP_BASE_URL + "/"}><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/logo.png" } alt="logo" /></a>
                        </div>
                        <div className="carousel-content slider-2-txt">
                            <h3>Trusted</h3>
                            <h2>Industrial<br/>Distributor</h2>
                        </div>
                    </div>
                </div>

                <div className="carousel-item">
                    <div className="slider slider-3">
                        <img src={ process.env.REACT_APP_BASE_URL + "/assets/img/slide-banner-3.png" } alt="slide-banner-3" />
                    </div>
                    <div className="carousel-caption">
                        <div className="carousel-img">
                            <a href={process.env.REACT_APP_BASE_URL + "/"}><img src={ process.env.REACT_APP_BASE_URL + "/assets/img/logo.png" } alt="logo" /></a>
                        </div>
                        <div className="carousel-content slider-3-txt">
                            <h2>500,000+</h2>
                            <h3>Unique Products</h3>
                        </div>
                    </div>
                </div> 
            </div> 

            
            <a href="#carousel" className="carousel-control-prev" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only"></span>
            </a>
            <a href="#carousel" className="carousel-control-next" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only"></span>
            </a>
        </div>

      </div>
    );
  }
  
  export default BannerSlider;